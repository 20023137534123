<template>
    <div id="dashboard-layout">
        <div class="page-container">
            <div class="container-fluid page-content">
                <NavbarComponent @logout="logout" />
                <router-view />
            </div>

            <!-- Button trigger modal -->

            <div class="side-menu-icon mobile-side-menu">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#mobileModal">
                    <i class="fa fa-bars"></i>
                </button>

            </div>

            <!-- Modal -->
            <div class="modal fade modal-mobile-menu" id="mobileModal" tabindex="-1" role="dialog"
                aria-labelledby="mobileModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-mobile-menu" role="document">
                    <div class="modal-content modal-content-mobile-menu">
                        <div class="modal-header">
                            <h5 class="modal-title" id="mobileModalLabel">الاقسام</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body modal-body-mobile">
                            <ul class="pages-list" v-if="role">
                                <li class="menu-page" v-for="(link, index) in links" :key="index">
                                    <router-link v-if="!link.roles || link.roles.includes(role)"
                                        :to="{ name: link.route }" class="link-page">
                                        <div class="link-content" :class="{ 'active': isActive(link.route) }">
                                            {{ link.name }}
                                            <i class="menu-icon" :class="link.icon"></i>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="modal-footer modal-footer-mobile">
                            <button type="button" class="btn btn-primary w-100">تسجيل الخروج</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="navbar-vertical">
                <div class="spacer-content">
                    <li class="logo-li">
                        <img src="../assets/images/logo.png" class="side-logo-image" :style="logoStyle">
                    </li>

                    <div class="pages-continer">
                        <ul class="pages-list" v-if="role">
                            <li class="menu-page" v-for="(link, index) in links" :key="index">
                                <router-link v-if="!link.roles || link.roles.includes(role)" :to="{ name: link.route }"
                                    class="link-page">
                                    <div class="link-content" :class="{ 'active': isActive(link.route) }">
                                        {{ link.name }}
                                        <i class="menu-icon" :class="link.icon"></i>
                                    </div>
                                </router-link>
                            </li>

                            <!-- <li class="menu-page">
                                <a @click="logout()" class="link-page">
                                    <div class="link-content logout-btn">
                                        تسجيل خروج
                                        <i class="menu-icon fa fa-sign-out"></i>
                                    </div>
                                </a>
                            </li> -->
                        </ul>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavbarComponent from '@/components/NavbarComponent.vue';

export default {
    data() {
        return {
            payload: null,
            role: null,
            links: [
                {
                    name: 'الرئيسية',
                    icon: 'fa fa-home',
                    route: 'home',
                    roles: ['super-admin', 'admin', 'accountant']
                },
                {
                    name: 'ادارة المجمعات',
                    icon: 'fa fa-object-group',
                    route: 'groups',
                    roles: ['super-admin', 'admin']
                },
                {
                    name: 'ادارة العقارات',
                    icon: 'fa fa-building',
                    route: 'real-estate',
                    roles: ['super-admin', 'admin']
                },
                {
                    name: 'ادارة العملاء',
                    icon: 'fa fa-users',
                    route: 'clients',
                    roles: ['super-admin', 'admin', 'accountant']
                },
                {
                    name: 'المبيعات',
                    icon: 'fa fa-tag',
                    route: 'sales',
                    roles: ['super-admin', 'admin', 'accountant']
                },
                {
                    name: 'تسديد',
                    icon: 'fa fa-money',
                    route: 'payment',
                    roles: ['super-admin', 'admin', 'accountant']
                },
                {
                    name: 'الدفعات',
                    icon: 'fa fa-flag',
                    route: 'payments',
                    roles: ['super-admin', 'admin', 'accountant']
                },
                {
                    name: 'سجل نقل الملكية',
                    icon: 'fa fa-exchange',
                    route: 'transfare',
                    roles: ['super-admin', 'admin']
                },
                {
                    name: 'المستخدمين',
                    icon: 'fa fa-user',
                    route: 'users',
                    roles: ['super-admin', 'admin']
                },
                {
                    name: 'العملاء المحتملين',
                    icon: 'fa fa-handshake-o',
                    route: 'potential-customers',
                    roles: ['super-admin', 'admin', 'reception']
                }
            ],
            with: '17',
            applyStyles: false,
        };
    },
    computed: {
        divStyles() {
            return {
                width: this.applyStyles ? '0pc' : '17pc',
                transition: 'width 0.5s',
            };
        },
        logoStyle() {
            return {
                width: this.applyStyles ? '0pc' : '6pc',
                transition: 'width 0.5s',
            };
        },
    },

    beforeMount() {
        this.getToken();
        this.pushDataToIndexOne();
    },

    methods: {
        pushDataToIndexOne() {
            let type = localStorage.getItem('type');
            const newData = {
                name: type == 'buildings' ? 'ادارة البنايات' : 'ادارة المربعات السكنية',
                // name: 'ادارة المربعات السكنية',
                icon: 'fa fa-building-o',
                route: 'buildings',
                roles: ['super-admin', 'admin']
            };
            this.links.splice(2, 0, newData);
        },
        async getToken() {
            await this.$store.dispatch('getToken');
            this.payload = this.$store.state.payload;
            this.role = this.$store.state.payload.role;;
        },
        logout() {
            localStorage.removeItem('token');
            this.$router.push({ name: 'login' });
        },
        isActive(routeName) {
            if (this.$route.name == 'report' && routeName == 'account-statement') {
                return true;
            }
            return this.$route.name === routeName;
        },
        toggleStyles() {
            this.applyStyles = !this.applyStyles;
        },
    },
    components: { NavbarComponent }
};
</script>
