
<template>
            <div class="col-sm-6 col-md-6 col-lg-6">
          <div class="card h-100">
            <div class="card-title small-card-title">
              المبنى 13
            </div>
            <div class="card-body-1">

              <div class="row">
                
                <div class="col-6 building-line">
                  <div class="building-info">
                    <span class="building-title">رقم العقار</span>
                    <span class="building-value">{{ real_estate?.building?.name }}</span>
                  </div>
                </div>
                
                <div class="col-6">
                  <div class="building-info">
                    <span class="building-title">رقم الطابق</span>
                    <span class="building-value">{{ real_estate?.building?.floors }}</span>
                  </div>
                </div>
                
                <div class="col-6 building-line">
                  <div class="building-info">
                    <span class="building-title">فئة العقار</span>
                    <span class="building-value">{{ real_estate?.type == 'apartment' ? 'شقة' : 'بيت' }}</span>
                  </div>
                </div>
                
                <div class="col-6">
                  <div class="building-info">
                    <span class="building-title">المساحة الكلية</span>
                    <span class="building-value">{{ real_estate?.total_area }} م2</span>
                  </div>
                </div>
                
                <div class="col-6 building-line">
                  <div class="building-info">
                    <span class="building-title">نوع العقار</span>
                    <span class="building-value">سكني</span>
                  </div>
                </div>
                
          
    
              </div>

            </div>
          </div>
        </div>
</template>

<script>
export default({
  props: ['real_estate']
})
</script>
