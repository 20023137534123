import Vue from 'vue';

export default {
  actions: {
    getReports({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/report', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    showReport({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/report/${id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },


  },
};
