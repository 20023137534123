<template>
  <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="addModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-side" role="document">
      <div class="modal-content modal-content-side">
        <div class="modal-header">
          <h5 class="modal-title" id="addModalTitle">
            <i class="fa fa-plus"></i>
            اضافة بيع جديد
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-side">



          <div class="form-group">
            <label for="name">العميل</label>
            <v-select v-model="selected.clients" :options="clients" label="name" :dir="'rtl'" track-by="id"
              :searchable="true" @search="getClients" placeholder="ابحث عن العميل.." />
            <div v-if="errors.client_id">
              <p class="input-error" v-for="(error, index) in errors.client_id" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="name">العقار</label>
            <v-select v-model="selected.real_estates" :options="real_estates" label="name" :dir="'rtl'" track-by="id"
              :searchable="true" @search="getRealEstates" placeholder="ابحث عن العقار.." />
            <div v-if="errors.real_estate_id">
              <p class="input-error" v-for="(error, index) in errors.real_estate_id" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="contract_date">تاريخ العقد</label>
            <input type="date" id="contract_date" class="form-control red-input-border" v-model="addForm.contract_date">
            <div v-if="errors.contract_date">
              <p class="input-error" v-for="(error, index) in errors.contract_date" :key="index">{{ error }}</p>
            </div>
          </div>


          <div class="form-group">
            <label for="payment_method">نوع التسديد</label>
            <select id="payment_method" class="form-control red-input-border" v-model="addForm.payment_method">
              <option value="installment">اقساط</option>
              <option value="cash">نقدا</option>
            </select>
            <div v-if="errors.payment_method">
              <p class="input-error" v-for="(error, index) in errors.payment_method" :key="index">{{ error }}</p>
            </div>
          </div>


          <!-- <div class="form-group">
            <label for="payment_type">طريقة تسديد الدفعة الاولى</label>
            <select id="payment_type" class="form-control red-input-border" v-model="addForm.payment_type">
              <option value="cash">نقدا</option>
              <option value="bank">تحويل مصرفي</option>
            </select>
            <div v-if="errors.payment_type">
              <p class="input-error" v-for="(error, index) in errors.payment_type" :key="index">{{ error }}</p>
            </div>
          </div> -->


          <div class="form-group">
            <label for="selling_amount">سعر البيع </label>
            <input type="text" id="selling_amount" class="form-control red-input-border"
              v-model="addForm.selling_amount" @keyup="setMonthlyAmount('selling_amount')" >
              <P class="numberWord">{{ selling_amount_word }}</P>
              <div v-if="errors.selling_amount">
              <p class="input-error" v-for="(error, index) in errors.selling_amount"  :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="percentage_discount">خصم (نسبة مئوية)</label>
            <input type="number" id="percentage_discount" class="form-control red-input-border"
              v-model="addForm.percentage_discount" @keyup="setMonthlyAmount('none')">

              <div v-if="errors.percentage_discount">
              <p class="input-error" v-for="(error, index) in errors.percentage_discount" :key="index">{{ error }}</p>
            </div>
          </div>

          <div v-if="addForm.payment_method == 'cash'">
            <div class="form-group">
              <label for="selling_amount_after_discount">سعر البيع بعد الخصم </label>
              <input type="number" id="selling_amount_after_discount" class="form-control red-input-border"
                v-model="addForm.selling_amount_after_discount" @keyup="setMonthlyAmount('selling_amount_after_discount')" disabled>
            </div>
          </div>


          <div v-if="addForm.payment_method == 'installment'">
            <div class="form-group">
              <label for="number_of_payments">عدد الدفعات</label>
              <input type="number" id="number_of_payments" class="form-control red-input-border"
                v-model="addForm.number_of_payments" @keyup="setMonthlyAmount('none')">
                <div v-if="errors.number_of_payments">
              <p class="input-error" v-for="(error, index) in errors.number_of_payments" :key="index">{{ error }}</p>
            </div>
            </div>


            <div class="form-group">
              <label for="first_amount">المقدمة</label>
              <input type="text" id="first_amount" class="form-control red-input-border"
                v-model="addForm.first_amount" @keyup="setMonthlyAmount('first_amount')">
                <P class="numberWord" >{{ first_amount_word }}</P>
                <div v-if="errors.first_amount">
              <p class="input-error" v-for="(error, index) in errors.first_amount" :key="index">{{ error }}</p>
            </div>
            </div>

            <div class="form-group">
              <label for="receipt_amount">دفعة التسليم</label>
              <input type="text" id="receipt_amount" class="form-control red-input-border"
                v-model="addForm.receipt_amount" @keyup="setMonthlyAmount('receipt_amount')">
                <P class="numberWord">{{ receipt_amount_word }}</P>
                <div v-if="errors.receipt_amount">
              <p class="input-error" v-for="(error, index) in errors.receipt_amount" :key="index">{{ error }}</p>
            </div>
            </div>



            <div class="form-group">
              <label for="installment_amount">مبلغ الدفعات الشهرية</label>
              <input type="number" id="installment_amount" class="form-control red-input-border" 
                v-model="addForm.installment_amount" disabled>
                <div v-if="errors.installment_amount">
              <p class="input-error" v-for="(error, index) in errors.installment_amount" :key="index">{{ error }}</p>
            </div>
            </div>
          </div>


          <hr>
          <button type="button" class="btn btn-primary  btn-primary-100" @click="addSale()">
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-floppy-o save-icon" v-else></i> اضافة

          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { toArabicWord } from "number-to-arabic-words/dist/index-node.js";

export default ({
  data() {
    return {
      loading_spinner: false,
      clients: [],
      real_estates: [],
      errors: [],
      selected: {
        real_estates: null,
        clients: null,
      },
      addForm: {
        first_amount: '',
        payment_method: 'installment',
        client_id: '',
        percentage_discount: 0,
        real_estate_id: '',
        contract_date: '',
        number_of_payments: '',
        selling_amount: '',
        installment_amount: '',
        receipt_amount: 0,
        selling_amount_after_discount: '',
        // payment_type: 'cash',
      },
      selling_amount_word: '',
      first_amount_word: '',
      receipt_amount_word: '',
    }
  },


  methods: {
    async addSale() {

      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;

      this.addForm.client_id = this.selected.clients == null ? null : this.selected.clients.id;
      this.addForm.real_estate_id = this.selected.real_estates == null ? null : this.selected.real_estates.id;
      this.addForm.receipt_amount =   this.addForm.receipt_amount == '' ? 0 : this.addForm.receipt_amount;
      this.addForm.first_amount =   this.addForm.first_amount == '' ? 0 : this.addForm.first_amount;
      this.addForm.selling_amount =   this.addForm.selling_amount == '' ? 0 : this.addForm.selling_amount;
      this.addForm.percentage_discount =   this.addForm.percentage_discount == '' ? 0 : this.addForm.percentage_discount;
      
      // remove comma from numbers
      this.addForm.receipt_amount = this.removeCommas(this.addForm.receipt_amount);
      this.addForm.first_amount = this.removeCommas(this.addForm.first_amount);
      this.addForm.selling_amount = this.removeCommas(this.addForm.selling_amount);
      
      console.log(this.addForm.first_amount);
      console.log(this.addForm.receipt_amount);
      console.log(this.addForm.selling_amount);

      await this.$store.dispatch('addSale', this.addForm)
        .then(response => {
          this.addForm = {
            payment_method: 'installment',
            client_id: null,
            percentage_discount: '',
            real_estate_id: null,
            contract_date: '',
            number_of_payments: '',
            selling_amount: '',
            installment_amount: '',
            receipt_amount: '',
            // payment_type: 'cash',
          }
    
          this.$emit('addSale', response.data.data);
          $('#addModal').modal('hide');
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تمت الاضافة بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
          this.$emit('getData');
          this.loading_spinner = false  ;

        })
        .catch(error => {
          this.errors = error.response.data.errors;});
        this.real_estates = [];
        this.loading_spinner = false;
    },
    setMonthlyAmount(type) {
    if (type === 'first_amount' || type === 'receipt_amount' || type === 'selling_amount' || type === 'selling_amount_after_discount') {
        this.addForm[type] = this.removeCommas(this.addForm[type]); // Remove non-numeric characters
    }

    // Add comma separator for numbers
    if (type === 'first_amount' || type === 'receipt_amount' || type === 'selling_amount' || type === 'selling_amount_after_discount') {
        let tempNumber = this.addForm[type].replace(/\D/g, ''); // Remove non-numeric characters
        let commaSeparatedNumber = tempNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.addForm[type] = commaSeparatedNumber;
    }

    if (type === 'first_amount' || type === 'receipt_amount' || type === 'selling_amount' || type === 'selling_amount_after_discount') {
    if (this.addForm[type] == null) {
        var word = toArabicWord(0);
        this.words = word + " " + "دينار عراقي";
        return;
      }
      var word = toArabicWord(this.removeCommas(this.addForm[type]));
      if (type === 'selling_amount') {
        this.selling_amount_word = word + " " + "دينار عراقي";
      } else if (type === 'first_amount') {
        this.first_amount_word = word + " " + "دينار عراقي";
      } else if (type === 'receipt_amount') {
        this.receipt_amount_word = word + " " + "دينار عراقي";
      }
    
    }

    if (this.addForm.payment_method === 'cash') {
        var discount = this.removeCommas(this.addForm.selling_amount) * this.removeCommas(this.addForm.percentage_discount) / 100;
        this.addForm.selling_amount_after_discount = this.removeCommas(this.addForm.selling_amount) - discount;
    } else {
        let selling_amount = this.removeCommas(this.addForm.selling_amount);
        if (this.addForm.percentage_discount) {
            var discount = selling_amount * this.removeCommas(this.addForm.percentage_discount) / 100;
            selling_amount -= discount;
        }
        this.addForm.installment_amount = (selling_amount - this.removeCommas(this.addForm.receipt_amount) - this.removeCommas(this.addForm.first_amount)) / this.removeCommas(this.addForm.number_of_payments);
    }
},

removeCommas(value) {
    if (typeof value === 'string') {
        return value.replace(/,/g, '');
    }
    return value;
},
    getClients(query) {
      this.loading = true;
      this.$store.dispatch('getClientsList', {
        search: query,
      })
        .then((response) => {
          this.clients = response.data.data;
        });
    },
    getRealEstates(query) {
      this.$store.dispatch('getRealEstate', {
        search: query,
        status: 'available'
      })
        .then(response => {
          this.real_estates = response.data.data;
        })
        .catch(error => {
        });
    }
  }
})
</script>