export function checkRole(to, from, next) {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = decodeToken(token);
    const userRole = decodedToken.role;

    if (decodedToken.exp * 1000 > Date.now()) {
      const allowedRoles = to.meta.roles;
      if (allowedRoles && allowedRoles.includes(userRole)) {
        next();
      } else {
        next('/unauthorized');
      }
      next();
    } else {
      next('/unauthorized');
    }
  } else {
    next('/');
  }
}

export function loginRedirect(to, from, next) {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = decodeToken(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      console.log("1")
      next();
    } else {
      console.log("2")
      next('/dashboard/');
    }
  } else {
    next('/');
  }
}



export function checkUserRole(to, from, next) {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = decodeToken(token);
    const userRole = decodedToken.role;
    const allowedRoles = to.meta.roles;
    if (allowedRoles && allowedRoles.includes(userRole)) {
      next();
    } else {
      next('/');
    }
  } else {
    next('/');
  }
}



function decodeToken(token) {
  const tokenParts = token.split('.');
  const payload = JSON.parse(unescape(decodeURIComponent(escape(atob(tokenParts[1])))));
  return payload;
}