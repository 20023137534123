<template>
    <div style="background-color: #fff; height: 100vh;">
        <div class="alert-alert-warning" v-if="error">
            <div class="alert-icon">
                <i class="fa fa-exclamation-triangle"></i>
            </div>
            <div class="alert-message">
                <h4>تنبيه</h4>
                <p>لا يوجد بيانات</p>
            </div>
        </div>

        <div class="print-invoice-2" v-else>

            <div class="card">
                <div class="invoice-body">
                    <div class="row">
                        <div class="col-9">
                            <div class="row center-invoice-nav">
                                <div class="col-4">
                                    <div class="logo-side">
                                        <img :src="selected_contract.complex.logo" class="logo-invoice">
                                        <br>
                                        <span>{{ selected_contract.complex.name }}</span>
                                    </div>
                                </div>
                                <div class="col-4 text-center">
                                    <h4 class="invoince-title">وصل قبض</h4>
                                    <h4 class="invoince-title">RECEIPT VOUCHER</h4>
                                </div>
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-6 text-left">رقم الوصل</div>
                                        <div class="col-6 text-right">Receipt No</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 number-box-container">
                                            <div class="number-box" 
                                                >{{ selected_contract.id }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row section-invoice-2">
                                <div class="col-6">
                                    <div class="sales-center">
                                        <span class="sales-center">مركز المبيعات</span>
                                        <span class="sales-center">SALES CENTER</span>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="invoice-money">
                                        <div class="total-amount">
                                            <div class="cont-title">
                                                <div class="total-amount-title">المبلغ الكلي</div>
                                            </div>
                                            <div class="cont-value">
                                                <div class="total-amount-value">{{ selected_contract.sale.total_amount |
                                                    currency }}</div>
                                            </div>
                                        </div>

                                        <div class="total-amount">
                                            <div class="cont-title">
                                                <div class="total-amount-title">المبلغ المتبقي</div>
                                            </div>
                                            <div class="cont-value">
                                                <div class="total-amount-value green-value">
                                                    {{ selected_contract.sale.remaining_amount | currency }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 p-t-1">
                                    <div class="invoice-body-content">
                                        <div class="invoice-body-line">
                                            <div class="invoice-body-title">استلمنا من</div>
                                            <div class="invoice-body-value">السييد \ {{ selected_contract.client.name }}
                                            </div>
                                            <div class="invoice-body-title">Recived from</div>
                                        </div>
                                        <div class="invoice-body-line">
                                            <div class="invoice-body-title">مبلغ وقدره</div>
                                            <div class="invoice-body-value">{{ selected_contract.payment.amount }}</div>
                                            <div class="invoice-body-title">The amount of</div>
                                        </div>
                                        <div class="invoice-body-line">
                                            <div class="invoice-body-title">وذلك عن</div>
                                            <div class="invoice-body-value">{{ selected_contract.payment.for }}</div>
                                            <div class="invoice-body-title">Payment For</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="row">
                                <div class="col-6">تاريخ الوصل</div>
                                <div class="col-6 text-left">Date</div>
                                <div class="col-12 text-center">
                                    <span class="invoice-date">{{ selected_contract.payment.date }}</span>
                                    <br>
                                    <qr-code style="justify-content: center;display: grid;" :text="selected_contract.qr_url"
                                        size="200"></qr-code>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="invoice-footer">
                        العنوان : {{ selected_contract.complex.address }}
                    </div>
                </div>
                <button class="btn btn-primary" style="font-family: 'cairo'; margin: 1pc;" @click="printPaper()"><i
                        class="fa fa-print"> طباعة</i></button>
            </div>


        </div>


    </div>
</template>


<script>
export default {
    data() {
        return {
            id: this.$route.params.id,
            selected_contract: null,
            error: false,
        };
    },
    mounted() {
        this.print();
    },
    methods: {
        printPaper() {
            window.print();
        },
        print() {
            this.$store.dispatch('printPublicPaymentByHash', {
                id: this.id
            })
                .then(response => {
                    this.selected_contract = response.data;
                    // this.selected_contract.id = [...`${response.data.id}`];
                    console.log(this.selected_contract)
                })
                .catch(error => {
                    this.error = true;
                });

        },
    },
};
</script>
<style scoped>
@page {
    size: 7in 9.25in;
    margin: 27mm 16mm 27mm 16mm;
}

@media print {
    .btn {
        display: none;
    }
}

.alert-alert-warning {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 26px;
}

i.fa.fa-exclamation-triangle {
    color: red;
    font-size: 60px;
    padding-top: 3pc;
}

@media only screen and (max-width: 1000px) {
    .card {
        overflow: auto;
    }

    .invoice-body {
        width: 60pc;
    }
}</style>