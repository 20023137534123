<template>
  <div>
    <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="addModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-side" role="document">
        <div class="modal-content modal-content-side">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalTitle">
              <i class="fa fa-plus"></i>
              اضافة مستخدم
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body modal-side">
            <div class="form-group">
              <label for="name">الاسم</label>
              <input type="text" class="form-control red-input-border" v-model="addForm.name">
              <div v-if="errors.name">
                <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group">
              <label for="phone">رقم الهاتف</label>
              <input type="number" class="form-control red-input-border" v-model="addForm.phone">
              <div v-if="errors.phone">
                <p class="input-error" v-for="(error, index) in errors.phone" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group">
              <label for="password">كلمة المرور</label>
              <input type="text" class="form-control red-input-border" v-model="addForm.password">
              <div v-if="errors.password">
                <p class="input-error" v-for="(error, index) in errors.password" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group">
              <label for="name">
                      <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>

              </label>
              <v-select v-model="addForm.complexes" :options="complexes" label="name" :dir="'rtl'" track-by="id"
                :searchable="true" @search="getGroups" placeholder="ابحث ..." 
                multiple
                />
              <div v-if="errors.complexes">
                <p class="input-error" v-for="(error, index) in errors.complexes" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group">
              <label for="role">الصلاحيات</label>
              <select name="role" id="role" class="form-control red-input-border" v-model="addForm.role">
                <option v-if="role == 'super-admin'" value="super-admin">مدير النظام</option>
                <option value="admin">مدير</option>
                <option value="accountant">محاسب</option>
                <option value="reception">موظف استقبال</option>
              </select>
              <div v-if="errors.role">
                <p class="input-error" v-for="(error, index) in errors.role" :key="index">{{ error }}</p>
              </div>
            </div>



            <hr>
            <button type="button" class="btn btn-primary  btn-primary-100" @click="addUser()">
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-floppy-o save-icon" v-else></i> اضافة
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading_spinner: false,
      complexes: [],
      addForm: {
        name: '',
        phone: '',
        password: '',
        role: 'admin',
        complexes: [],
      },
      errors: [],
      role: null,
    }
  },
  mounted() {
    this.getGroups();
    this.getToken();
  },
  methods: {
        async getToken() {
            await this.$store.dispatch('getToken');
            this.role = this.$store.state.payload.role;
        },
    getGroups(query) {
      this.$store.dispatch('getGroups', {
        search: query,
      })
        .then(response => {
          this.complexes = response.data.data;
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    async addUser() {
      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;
      var complexes = [];
      this.addForm.complexes.forEach(complex => {
        complexes.push(complex.id);
      });
      var data = {
        name: this.addForm.name,
        phone: this.addForm.phone,
        password: this.addForm.password,
        role: this.addForm.role,
        complexes: complexes
      }
      await this.$store.dispatch('addUser', data)
        .then(response => {
          this.$emit('addUser', response.data);
          $('#addModal').modal('hide');
          this.addForm = {
            name: '',
            phone: '',
            password: '',
            compldexes: [],
            role: 'admin',
          }
          this.errors = [];
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تمت الاضافة بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
          this.$emit('getData');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
        this.loading_spinner = false;
    }
  }
}
</script>