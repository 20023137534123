<template>
  <div class="modal fade" id="showTransfareModal" tabindex="-1" role="dialog" aria-labelledby="showTransfareModalTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-side" role="document">
      <div class="modal-content modal-content-side">
        <div class="modal-header">
          <h5 class="modal-title" id="showTransfareModalTitle">
            <i class="fa fa-plus"></i>
            نقل الملكية
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-side">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="front">اسم او رقم العقار</label>
              <input type="text" class="form-control red-input-border" id="front" v-model="real_estate.name" disabled>
            </div>


            <div class="form-group col-md-12">
              <label for="front">المالك الحالي</label>
              <input type="text" class="form-control red-input-border" id="front" v-model="client.name" disabled>
            </div>

            <div class="form-group col-md-12">
              <label for="name">المالك الجديد</label>
              <v-select v-model="selected.clients" :options="clients" label="name" :dir="'rtl'" track-by="id"
                :searchable="true" @search="getClients" placeholder="ابحث عن العميل.." />
              <div v-if="errors.client_id">
                <p class="input-error" v-for="(error, index) in errors.client_id" :key="index">{{ error }}</p>
              </div>
            </div>


          </div>

          <hr>
          <button type="button" class="btn btn-primary  btn-primary-100" @click="transfareOwnership()">
            <div class="spinner-border" role="status" v-if="loading_spinner">
              <span class="visually-hidden"></span>
            </div>
            <i class="fa fa-exchange save-icon" v-else></i> نقل
          </button>

        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return {
      loading_spinner: false,
      client: '',
      real_estate: '',
      clients: [],
      errors: [],
      selected: {
        clients: null,
      },
      addForm: {
        real_estate_id: '',
        old_client_id: '',
        new_client_id: '',
      }
    }
  },
  methods: {
    transfareOwnership() {
      if (this.selected.clients.id == this.client.id) {
        this.$swal({
          position: 'center',
          icon: 'error',
          title: 'لا يمكن نقل الملكية للعميل نفسة',
          showConfirmButton: false,
          timer: 1500
        });
      }

      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;
      this.addForm.new_client_id = this.selected.clients == null ? null : this.selected.clients.id;

      this.addForm.real_estate_category_id = this.selected.real_estate_category_id == null ? null : this.selected.real_estate_category_id.id;
      this.$store.dispatch('transfareRealEstate', this.addForm)
        .then(response => {
          this.$emit('editRealEstate', response.data);
          $('#showTransfareModal').modal('hide');
          this.addForm = {
            new_client_id: '',
            old_client_id: '',
            real_estate_id: '',
          }
          this.selected = {
            clients: null,
          }
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تم نقل الملكية بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
          this.$emit('getData');
          this.loading_spinner = false;
        })
        .catch(error => {
          this.errors = error.response.data.errors;
          this.loading_spinner = false;
        });
    },


    getCurrentOwner(client, real_estate) {
      this.client = client;
      this.real_estate = real_estate;

      this.addForm.old_client_id = client.id;
      this.addForm.real_estate_id = real_estate.id;
    },
    getClients(query) {
      this.loading = true;
      this.$store.dispatch('getClientsList', {
        search: query,
      })
        .then((response) => {
          this.clients = response.data.data;
        });
    },
  }
})
</script>
