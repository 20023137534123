<template>
  <div class="modal fade" id="modalEdit" tabindex="-1" role="dialog" aria-labelledby="modalEditTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-side" role="document">
      <div class="modal-content modal-content-side">
        <div class="modal-header">
          <h5 class="modal-title" id="modalEditTitle">
            <i class="fa fa-plus"></i>
            تعديل 
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-side">


          <div class="form-group">
            <label for="name">
                      <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>
            </label>
            <v-select v-model="selected_complex" :options="complexes" label="name" :dir="'rtl'" track-by="id"
              :searchable="true" @search="getGroups" placeholder="ابحث عن مجمع" />
            <div v-if="errors.complex_id">
              <p class="input-error" v-for="(error, index) in errors.complex_id" :key="index">{{ error }}</p>
            </div>
          </div>


          <div class="form-group">
            <label for="name">اسم او رقم البناية</label>
            <input type="text" class="form-control red-input-border" id="name" v-model="editForm.name">
            <div v-if="errors.name">
              <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{ error }}</p>
            </div>
          </div>





          <div class="form-group" v-if="type == 'vertical'">
            <label for="floors">عدد الطوابق</label>
            <input type="number" class="form-control red-input-border" id="floors" v-model="editForm.floors">
            <div v-if="errors.floors">
              <p class="input-error" v-for="(error, index) in errors.floors" :key="index">{{ error }}</p>
            </div>
          </div>




          <div class="form-group">
            <label for="status">الحالة</label>
            <select class="form-control red-input-border" id="status" v-model="editForm.status">
              <option value="completed">مكتمل</option>
              <option value="incompleted">قيد الانشاء</option>
            </select>
            <div v-if="errors.status">
              <p class="input-error" v-for="(error, index) in errors.status" :key="index">{{ error }}</p>
            </div>
          </div>


          <div class="form-group">
            <label for="map">خارطة البناية</label>
            <label for="upload-logo" class="upload-logo-label">
              <div class="upload-image-container">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <span class="extentions">PDF</span>
              </div>
            </label>
            <input type="file" class="form-control red-input-border" id="upload-logo" hidden @change="onFileChange">
            <div v-if="errors.map">
              <p class="input-error" v-for="(error, index) in errors.map" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="name">
              <span>الخدمات</span>
            </label>
            <v-select v-model="editForm.service" :options="service" label="name" :dir="'rtl'" track-by="id"
              :searchable="true" @search="getGroups" placeholder="ابحث ..." multiple />
            <div v-if="errors.service">
              <p class="input-error" v-for="(error, index) in errors.service" :key="index">{{ error }}</p>
            </div>
          </div>


          <hr>
          <button type="button" class="btn btn-primary  btn-primary-100" @click="editBuilding">
            <div class="spinner-border" role="status" v-if="loading_spinner">
              <span class="visually-hidden"></span>
            </div>
            <i class="fa fa-floppy-o save-icon" v-else></i> حفظ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return {
      loading_spinner: false,
      complexes: [],
      service: [
        "ماء",
        "كهرباء",
        "غاز"
      ],
      id: null,
      errors: [],
      selected_complex: null,
      type: '',
      editForm: {
        floors: '',
        complex_id: null,
        name: '',
        address: '',
        map: '',
        service: '',
        status: '',
      },
    }
  },
  watch: {
    selected_complex(newPage, oldPage) {
      this.type = newPage.type;
    },
  },
  mounted() {
    this.getGroups()
  },
  methods: {
    showBuilding(id) {
      this.id = id;
      this.$store.dispatch('showBuilding', {
        id: id,
      })
        .then(response => {
          console.log(response.data)
          this.editForm = response.data;
          this.editForm.complex_id = response.data.complex;
          this.selected_complex = response.data.complex;
          this.editForm.map = '';
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    getGroups(query) {
      this.$store.dispatch('getGroups', {
        search: query,
      })
        .then(response => {
          this.complexes = response.data.data;
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    loadOptions(query) {
      console.log('Query:', query);
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.editForm.map = file;
    },
    async editBuilding() {
      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;
      this.editForm.complex_id = this.selected_complex == null ? null : this.selected_complex.id;
      await this.$store.dispatch('editBuilding', {
        id: this.id,
        data: this.editForm,
      })
        .then(response => {
          this.editForm = {
            floors: '',
            complex_id: null,
            name: '',
            address: '',
            map: '',
            service: '',
            status: '',
          };
          this.selected_complex = null;
          this.$emit('getData');
          this.$swal({
            title: 'تم تعديل البناية بنجاح',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          });
          $('#modalEdit').modal('hide');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
        this.loading_spinner = false;
    }
  }
})
</script>
