<template>
  <div class="home w-100">
    <!-- Page content -->

    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body card_with_pa">
            <div class="card-title">
              <div class="left-options card-title-icons">
                <a
                  data-toggle="modal"
                  data-target="#addModal"
                  class="add-btn-options add-btn"
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>

                <a
                  v-if="selectedItems && selectedItems.length > 0"
                  @click="confirmDelete()"
                  id="deleteButton"
                  class="card-title-options delete-btn"
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>

                <a
                  v-if="selectedItems && selectedItems.length == 1"
                  id="editModal"
                  class="card-title-options edit-btn"
                  @click="openEditModal()"
                  data-toggle="modal"
                  data-target="#modalEdit"
                >
                  <i class="fa fa-cog" aria-hidden="true"></i>
                </a>
              </div>

              <div class="right-title">
                <span class="nav-title">ادارة صور العقار</span>
              </div>
            </div>

            <div
              class="alert alert-warning"
              v-if="images.length == 0"
              style="border-radius: 1pc"
            >
              لا يوجد صور لهذا العقار
            </div>

            <div class="row" v-else>
              <div
                class="col-sm-12 col-md-3 col-lg-3"
                v-for="(image, index) in images"
                :key="index"
              >
                <div
                  class="card card-images"
                  :class="{ selected: isSelected(image.id) }"
                  @click="handleSelection(image.id)"
                >
                  <div class="card-image-title">{{ image.title }}</div>
                  <div class="card-body card-image-body">
                    <img :src="image.image" class="realestate-image" />
                  </div>
                  <span style="text-align: right; color: red">
                    {{
                      image.type == "construction"
                        ? "مراحل البناء"
                        : "صور البناية"
                    }}
                  </span>
                  <p>نسبة الانجاز {{ image.building_percentage }}</p>
                  <div class="card-footer card-image-description">
                    {{ image.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddImageComponent @getData="getImages" :id="id" />
    <EditImageComponent ref="editImageComponent" @getData="getImages" />
  </div>
</template>

<script>
import AddImageComponent from "@/components/Image/AddImageComponent.vue";
import EditImageComponent from "@/components/Image/EditImageComponent.vue";

export default {
  components: {
    AddImageComponent,
    EditImageComponent,
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      images: [],
      selectedItems: [],
    };
  },
  mounted() {
    this.getImages();
  },
  methods: {
    openEditModal() {
      let id = this.selectedItems[0];
      $("#modalEdit").modal("show");
      this.$refs.editImageComponent.showImage(id);
    },
    getImages() {
      this.loading = true;
      this.$store
        .dispatch("getImages", {
          real_estate_id: this.id,
        })
        .then((response) => {
          this.loading = false;
          this.images = response.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    isSelected(itemId) {
      return this.selectedItems.includes(itemId);
    },
    handleSelection(itemId) {
      if (this.isSelected(itemId)) {
        this.selectedItems = this.selectedItems.filter((id) => id !== itemId);
      } else {
        this.selectedItems.push(itemId);
      }
    },
    async confirmDelete() {
      const result = await this.$swal.fire({
        title: "هل انت متأكد ؟",
        text: "لن تتمكن من التراجع عن هذا الاجراء!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم, احذفها!",
        cancelButtonText: "الغاء",
      });
      if (result.isConfirmed) {
        this.deleteImage();
      }
    },
    async deleteImage() {
      try {
        await this.$store.dispatch("deleteImage", {
          ids: this.selectedItems,
        });
        this.selectedItems = [];
        await this.getImages();
        this.$swal.fire("تم الحذف", "تم الحذف بنجاح", "success");
      } catch (error) {
        await this.$swal.fire("خطآ", "حدثت مشكلة اثناء الحذف", "error");
      }
    },
  },
};
</script>
