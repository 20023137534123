import Vue from 'vue';
import App from './App.vue';
import axiosInstance from './axios';
import router from './router';
import store from './store';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/css/style.css'
import './assets/font-awesome/css/font-awesome.min.css'
import * as VueGoogleMaps from 'vue2-google-maps';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VueCurrencyFilter from 'vue-currency-filter';
import VueQRCodeComponent from 'vue-qrcode-component'
import './registerServiceWorker'
Vue.prototype.$axios = axiosInstance;


Vue.use(VueCurrencyFilter, {
  symbol: 'IQD',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true,
});


Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCyPT6mmhy-UjOUQDP0KLrAJexusRCHZz0',
    libraries: 'places',
  },
});


Vue.component('loading-table-component', () => import('./components/LoadingTableComponent.vue'));
Vue.component('pagination-component', () => import('./components/Global/PaginationComponent.vue'));
Vue.component('v-select', vSelect)
Vue.component('qr-code', VueQRCodeComponent)

Vue.use(VueSweetalert2);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
