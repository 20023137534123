export default {
    mutations: {
      setToken({ commit }, token) {
        localStorage.setItem('token', token);
      },
      SET_PAYLOAD(state, payload) {
        state.payload = payload;
      },
      LOGOUT(state) {
        state.token = null;
        state.expirationDate = null;
        localStorage.removeItem('token');
      },
    },
  };
  