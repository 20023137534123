import Vue from 'vue';

export default {
  actions: {
    addGroup({ commit }, form) {
      const groupData = new FormData();
      groupData.append('logo', form.logo);
      groupData.append('name', form.name);
      groupData.append('address', form.address);
      groupData.append('lat', form.lat);
      groupData.append('lng', form.lng);
      groupData.append('type', form.type);
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post('/api/complex', groupData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteGroup({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.delete(`/api/complex/${params.ids}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    showGroup({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/complex/${id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editGroup({ commit }, data) {
      const groupData = new FormData();
      groupData.append('logo', data.form.logo);
      groupData.append('name', data.form.name);
      groupData.append('address', data.form.address);
      groupData.append('lat', data.form.lat);
      groupData.append('lng', data.form.lng);
      groupData.append('type', data.form.type);
      groupData.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post(`/api/complex/${data.id}`, groupData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getGroups({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/complex', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};
