<template>
  <div class="home w-100">
    <!-- Main Content -->
    <div class="content">
      <div class="card print-nav">
        <div class="card-body">
          <div class="card-title">
            <div class="left-options card-title-icons">
              <button class="btn btn-primary print-btn" @click="print()">
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-print" v-else></i>

              تحرير وصل

              </button>
            </div>
            <div class="right-title">
              <span class="nav-title">سجل كشف الحساب</span>
            </div>
          </div>
        </div>
      </div>

      <loading-table-component v-if="loading" />

      <div class="print" id="printableContent" v-else>
        <div class="row m-t-1 rtl-row">
          <BuildingComponen v-if="report" :real_estate="report.real_estate" />
          <ClientComponent v-if="report" :client="report.client" />
          <PaymentMethodComponent v-if="report" :report="report" />
          <PaidAndUnpaidComponent v-if="report" :report="report" :paid_installments="report.paid_installments" :unpaid_installments="report.unpaid_installments" />
        </div>
      </div>

    </div>




  </div>
</template>


<script>
import BuildingComponen from '@/components/Reports/BuildingComponent.vue';
import ClientComponent from '@/components/Reports/ClientComponent.vue';
import PaymentMethodComponent from '@/components/Reports/PaymentMethodComponent.vue';
import PaidAndUnpaidComponent from '@/components/Reports/PaidAndUnpaidComponent.vue';

export default {
  components: {
    BuildingComponen,
    ClientComponent,
    PaymentMethodComponent,
    PaidAndUnpaidComponent
  },
  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      report: null,
      loading_spinner: false,
    };
  },
  mounted() {
    this.showReport();
  },
  methods: {
    async showReport() {
      this.loading = true;
      await this.$store.dispatch('showReport', this.id)
        .then(response => {
          console.log(response.data)
          this.report = response.data;
        })
        .catch(error => {
        });
        this.loading = false;
    },
    print() {
      window.print();
    },
  },
};
</script>

<style scoped>
.card-title {
  margin-bottom: 0 !important;
}

@media print {
  .print-nav {
    display: none;
  }

}
</style>