import Vue from 'vue';

export default {
  actions: {
    getUsers({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/user', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    deleteUser({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.delete(`/api/user/${params.ids}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },



    editUser({ commit }, data) {
      const groupData = new FormData();
      groupData.append('name', data.form.name);
      groupData.append('phone', data.form.phone);
      groupData.append('role', data.form.role);
      groupData.append('pasword', data.form.pasword);
      groupData.append('complexes', data.form.complexes);
      groupData.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post(`/api/user/${data.id}`, groupData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    addUser({ commit }, form) {
      const realEstateData = new FormData();
      realEstateData.append('name', form.name);
      realEstateData.append('phone', form.phone);
      realEstateData.append('complexes', form.complexes);
      realEstateData.append('password', form.password);
      realEstateData.append('role', form.role);
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post('/api/user', realEstateData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },


    getUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/user/${id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

  },
};
