import Vue from 'vue';

export default {
  actions: {
    getImages({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/image', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    addImage({ commit }, form) {
      const buildingData = new FormData();
      buildingData.append('title', form.title);
      buildingData.append('description', form.description);
      buildingData.append('image', form.image);
      buildingData.append('building_percentage', form.building_percentage);
      buildingData.append('real_estate_id', form.real_estate_id);
      buildingData.append('type', form.type);
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post('/api/image', buildingData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    deleteImage({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.delete(`/api/image/${params.ids}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    editImage({ commit }, data) {
      const form = new FormData();
      form.append('title', data.form.title);
      form.append('description', data.form.description);
      form.append('image', data.form.image);
      form.append('type', data.form.type);
      form.append('building_percentage', data.form.building_percentage);
      form.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post(`/api/image/${data.id}`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    showImage({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/image/${id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};
