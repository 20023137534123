<template>
    <div>
        <!-- Add Modal -->
        <div class="modal fade" id="modalEdit" tabindex="-1" role="dialog" aria-labelledby="modalEditTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-side" role="document">
                <div class="modal-content modal-content-side">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalEditTitle">
                            <i class="fa fa-plus"></i>
                            تعديل  {{ title }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body modal-side">
                        <div class="form-group">
                            <label for="name">الاسم</label>
                            <input type="text" class="form-control red-input-border" name="name" id="name"
                                v-model="editForm.name">
                            <div v-if="errors.name">
                                <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{
                                    error }}</p>
                            </div>

                        </div>


                        <div class="form-group">
                            <label for="type">النوع</label>
                            <select class="form-control red-input-border" id="type" v-model="editForm.type">
                                <option value="vertical">عمودي</option>
                                <option value="horizontal">افقي</option>
                            </select>
                            <div v-if="errors.type">
                                <p class="input-error" v-for="(error, index) in errors.type" :key="index">{{ error }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="address">العنوان</label>
                            <input type="text" class="form-control red-input-border" name="address" id="address"
                                v-model="editForm.address">
                            <div v-if="errors.address">
                                <p class="input-error" v-for="(error, index) in errors.address" :key="index">{{
                                    error }}</p>
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="lng">خطوط الطول</label>
                                <input type="string" class="form-control red-input-border" id="lng" v-model="editForm.lng">
                                <div v-if="errors.lat">
                                    <p class="input-error" v-for="(error, index) in errors.lat" :key="index">{{
                                        error }}</p>
                                </div>

                            </div>
                            <div class="form-group col-md-6">
                                <label for="lat">خطوط العرض</label>
                                <input type="string" class="form-control red-input-border" id="lat" v-model="editForm.lat">
                                <div v-if="errors.lng">
                                    <p class="input-error" v-for="(error, index) in errors.lng" :key="index">{{
                                        error }}</p>
                                </div>

                            </div>
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn btn-secondary btn-primary-100" data-toggle="modal"
                                data-target="#mapModal">
                                <i class="fa fa-map"></i> الخريطة</button>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="edit-logo" class="edit-logo-label">
                                    <div class="upload-image-container">
                                        <i class="fa fa-upload" aria-hidden="true"></i>
                                        <span class="extentions">PNG, JPEG</span>
                                    </div>
                                </label>
                                <input type="file" class="form-control red-input-border" name="logo" id="edit-logo"
                                    accept="image/*" hidden @change="onImageChangeUpdate">
                                <div v-if="errors.logo">
                                    <p class="input-error" v-for="(error, index) in errors.logo" :key="index">{{
                                        error }}</p>
                                </div>

                            </div>
                            <div class="form-group col-md-6">
                                <img v-if="edit_url" :src="edit_url" class="edit-logo-review" id="logo-preview" />
                            </div>
                        </div>
                        <hr>
                        <button type="button" class="btn btn-primary  btn-primary-100" @click="updateGroup">
                            <div class="spinner-border" role="status" v-if="loading_spinner">
                                <span class="visually-hidden"></span>
                            </div>
                            <i class="fa fa-floppy-o save-icon" v-else></i> حفظ
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- // Add Modal -->
    </div>
</template>

<script>
export default ({
    data() {
        return {
            id: null,
            errors: [],
            edit_url: null,
            loading_spinner: false,
            title: '',
            editForm: {
                name: '',
                address: '',
                lat: 33.227872,
                lng: 44.377095,
                logo: '',
                type: 'vertical'
            },
        }
    },

    methods: {
        showGroup(id) {
            this.id = id;
            this.$store.dispatch('showGroup', id)
                .then(response => {
                    $('#modalEdit').modal('show');
                    this.title = response.data.name;
                    this.editForm = response.data;
                    this.edit_url = response.data.logo;
                    this.editForm.logo = '';
                    this.editForm.type = response.data.type;
                })
                .catch(error => {
                    this.$swal({
                        title: 'خطأ',
                        text: 'حدث خطأ أثناء تحميل البيانات',
                        type: 'error',
                        confirmButtonText: 'حسناً',
                    });
                });
        },
        onImageChangeUpdate(e) {
            const file = e.target.files[0];
            this.editForm.logo = file;
            this.edit_url = URL.createObjectURL(file);
        },
        async updateGroup() {
            if (this.loading_spinner) {
                return;
            }
            this.loading_spinner = true;
            let data = {
                id: this.id,
                form: this.editForm
            }
            await this.$store.dispatch('editGroup', data)
                .then(response => {
                    this.$swal.fire('تم', 'تم تعديل  بنجاح', 'success');
                    $('#modalEdit').modal('hide');
                    this.$emit('getData');
                    this.loading_spinner = false;
                })
                .catch(error => {
                    this.loading_spinner = false;
                    this.errors = error.response.data.errors;
                });
        },
    }
})
</script>
