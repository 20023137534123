import Vue from 'vue';

export default {
  actions: {
    getRealEstate({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/real-estate', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    realEstateHistory({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/real-estate/history', { params: params.query })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },


    editRealEstate({ commit }, data) {
      const groupData = new FormData();
      groupData.append('name', data.form.name);
      groupData.append('total_area', data.form.total_area);
      groupData.append('map', data.form.map);
      groupData.append('building_area', data.form.building_area);
      groupData.append('front', data.form.front);
      groupData.append('numbers_of_rooms', data.form.numbers_of_rooms);
      groupData.append('number_of_bathrooms', data.form.number_of_bathrooms);
      groupData.append('status', data.form.status);
      groupData.append('building_id', data.form.building_id);
      groupData.append('real_estate_category_id', data.form.real_estate_category_id);
      groupData.append('progress_ratio', data.form.progress_ratio);
      groupData.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post(`/api/real-estate/${data.id}`, groupData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    showRealEstate({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/real-estate/${params.id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    deleteRealEstate({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.delete(`/api/real-estate/${params.ids}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    addRealEstate({ commit }, form) {
      const realEstateData = new FormData();
      realEstateData.append('name', form.name);
      realEstateData.append('total_area', form.total_area);
      realEstateData.append('map', form.map);
      realEstateData.append('building_area', form.building_area);
      realEstateData.append('front', form.front);
      realEstateData.append('numbers_of_rooms', form.numbers_of_rooms);
      realEstateData.append('number_of_bathrooms', form.number_of_bathrooms);
      realEstateData.append('status', form.status);
      realEstateData.append('building_id', form.building_id);
      realEstateData.append('real_estate_category_id', form.real_estate_category_id);
      realEstateData.append('progress_ratio', form.progress_ratio);
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post('/api/real-estate', realEstateData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },


    transfareRealEstate({ commit }, form) {
      const realEstateData = new FormData();
      realEstateData.append('real_estate_id', form.real_estate_id);
      realEstateData.append('old_client_id', form.old_client_id);
      realEstateData.append('new_client_id', form.new_client_id);
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post('/api/real-estate/transfare', realEstateData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    getRealEstateCategory({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/real-estate-category', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    getRealEstateType({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/real-estate-type', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

  },
};
