<template>
  <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="showModalLabel" v-if="info">معلومات {{ info.name }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body show-card-body">
  
          <loading-table-component v-if="loading" />

          <div class="row" v-else>
            <div class="col-6 show-title">
              <b>الاسم</b>
            </div>
            <div class="col-6 show-value">{{ info?.name }}</div>

            <div class="col-6 show-title">
              <b>النوع</b>
            </div>
            <div class="col-6 show-value">{{ info?.type == 'vertical' ? 'عمودي' : 'افقي' }}</div>
             
            <div class="col-6 show-title">
              <b>العنوان</b>
            </div>
            <div class="col-6 show-value">
              <span class="badge badge-success" v-if="info?.address">{{ info?.address }}</span>
              <span class="badge badge-success" v-else>لا يوجد</span>
            </div>

            <div class="col-6 show-title">
              <b>العنوان على الخريطة</b>
            </div>
            <div class="col-6 show-value">
              <a v-if="info?.lat && info?.long" href="">عرض</a>
              <span v-else class="status-unavailable">لا يوجد</span>
            </div>


            <div class="col-6 show-title">
              <b>الشعار</b>
            </div>
            <div class="col-6 show-value">
            <img :src="info?.logo" class="show-logo">  
            </div>

          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">خروج</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  props: {
  },
  data() {
    return {
      info: null,
      loading: false,
    }
  },
  methods: {
    async showGroupInfo(id) {
      this.loading = true;
      this.id = id;
      await this.$store.dispatch('showGroup', id)
        .then(response => {
          this.info = response.data;
          console.log(this.info)
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
      this.loading = false;
    },
  }
})
</script>
