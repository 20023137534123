<template>
    <div class="modal fade" id="mapModal" tabindex="1" role="dialog" aria-labelledby="mapModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-side" role="document">
            <div class="modal-content modal-content-side map-modal">
                <div class="modal-header">
                    <h5 class="modal-title" id="mapModalTitle">
                        <i class="fa fa-plus"></i>
                        الخريطة
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body modal-side">

                    <gmap-map :center="center" :zoom="zoom" @click="handleMapClick" @dragend="handleMapDrag"
                        style="width: 100%; height: 400px;">
                        <gmap-marker :position="center" :draggable="true" @dragend="handleMarkerDrag"></gmap-marker>
                    </gmap-map>


                    <hr>
                    <button type="button" class="btn btn-primary btn-primary-100" data-dismiss="modal">
                        حفظ
                        <i class="fa fa-floppy-o save-icon"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default ({
    props: ["location"],
    data() {
        return {
            center: { lat: 33.227872, lng: 44.377095 },
            zoom: 10,
        }
    },
    methods: {
        handleMapClick(event) {
            this.center = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        },
        handleMapDrag() {
            console.log('Map dragged. New center:', this.center);
        },
        handleMarkerDrag(event) {
            let location = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            }
            this.$emit('location', location);
        },
    }
})
</script>
