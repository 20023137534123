<template>
  <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="showModalLabel" v-if="info">معلومات البناية {{ info.name }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body show-card-body">
          <loading-table-component v-if="loading" />

          <div class="row" v-else>
            <div v-if="info" class="show-info">
              <div class="col-6 show-title">
                <b>اسم البناية</b>
              </div>
              <div class="col-6 show-value">{{ info.name }}</div>
              <div class="col-6 show-title">
                <b>نوع البناية</b>
              </div>
              <div class="col-6 show-value">{{ info.type == 'horizontal' ? 'افقي' : 'عمودي' }}</div>
              <div class="col-6 show-title">
                <b>الطوابق</b>
              </div>
              <div class="col-6 show-value">
                <span class="badge badge-danger" v-if="info.type == 'horizontal'">لا يوجد طوابق</span>
                <span class="badge badge-success" v-else>{{ info.floors }}</span>
              </div>
              <div class="col-6 show-title">
                <b>
                  <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>
                </b>
              </div>
              <div class="col-6 show-value">{{ info.complex.name }}</div>
              <div class="col-6 show-title">
                <b>الخارطة</b>
              </div>
              <div class="col-6 show-value">
                <a v-if="info.map" :href="info.map">تنزيل</a>
                <span v-else class="status-unavailable">لا يوجد</span>
              </div>
              <div class="col-6 show-title">
                <b>الحالة</b>
              </div>
              <div class="col-6 show-value">
                <span class="badge badge-danger" v-if="info.status == 'incompleted'">قيد الانشاء</span>
                <span class="badge badge-success" v-else>مكتمل</span>
              </div>
              <div class="col-6 show-title">
                <b>العنوان</b>
              </div>
              <div class="col-6 show-value">{{ info.address }}</div>
              <div class="col-6 show-title">
                <b>الخدمات</b>
              </div>
              <div class="col-6 show-value">
                <pre v-if="info.service" class="pre-card">{{ info.service }}</pre>
                <span v-else class="status-unavailable">لا يوجد</span>
              </div>
            </div>
            </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">خروج</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return {
      info: null,
      loading: false,
    }
  },
  methods: {
    async showBuilding(id) {
      this.id = id;
      this.loading = true;
      await this.$store.dispatch('showBuilding', {
        id: id,
      })
        .then(response => {
          this.info = response.data;
          console.log(this.info)
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
      this.loading = false;
    },
  }
})
</script>
