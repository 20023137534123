import axios from 'axios';

let token = localStorage.getItem('token');
const axiosInstance = axios.create({
  baseURL: 'https://api.swahel-b.com',
  // baseURL: 'https://realestate-api.tatweer-tech.host',
  // baseURL: 'http://localhost:1212',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'Accept-Language': 'ar'
  },
});

export default axiosInstance;