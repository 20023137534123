import Vue from 'vue'
import VueRouter from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue';
import DashboardLayout from '../layouts/DashboardLayout.vue';
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import GroupsView from '../views/GroupsView.vue'
import BuildingsView from '../views/BuildingsView.vue'
import RealEstateView from '../views/RealEstateView.vue'
import RealEstateImagesView from '../views/RealEstateImagesView.vue'
import ClientsView from '../views/ClientsView.vue'
import SalesView from '../views/SalesView.vue'
import ReportsView from '../views/ReportsView.vue'
import AccountStatementView from '../views/AccountStatementView.vue'
import PaymentView from '../views/PaymentView.vue'
import TransfareView from '../views/TransfareView.vue'
import UsersView from '../views/UsersView.vue'
import PaymentsListView from '../views/PaymentsListView.vue'
import UnauthorizedView from '../views/UnauthorizedView.vue'
import AllPaymentsView from '../views/AllPaymentsView.vue'
import PublicPrintPayment from '../views/PublicPrintPayment.vue'
import PotentialCustomersView from '../views/PotentialCustomersView.vue'

import { checkRole, loginRedirect } from '../middleware/middleware';



Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '',
        name: 'login',
        component: LoginView,
        // beforeEnter: loginRedirect,
      },
      {
        path: 'unauthorized',
        name: 'unauthorized',
        component: UnauthorizedView,
      },
      {
        path: 'payment/:id',
        name: 'public_print_payment',
        component: PublicPrintPayment,
      },
    ],
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant', 'reception']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'groups',
        name: 'groups',
        component: GroupsView,
        meta: { 
          roles: ['super-admin', 'admin']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'buildings',
        name: 'buildings',
        component: BuildingsView,
        meta: { 
          roles: ['super-admin', 'admin']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'real-estate',
        name: 'real-estate',
        component: RealEstateView,
        meta: { 
          roles: ['super-admin', 'admin']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'real-estate/images/:id',
        name: 'real-estate-images',
        component: RealEstateImagesView,
        meta: { 
          roles: ['super-admin', 'admin']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'clients',
        name: 'clients',
        component: ClientsView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'sales',
        name: 'sales',
        component: SalesView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'payment',
        name: 'payment',
        component: PaymentView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'payment/:id',
        name: 'payment_id',
        component: PaymentView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'payments/list/:id',
        name: 'payments_list',
        component: PaymentsListView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'reports',
        name: 'reports',
        component: ReportsView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'account-statement',
        name: 'account-statement',
        component: AccountStatementView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'payments',
        name: 'payments',
        component: AllPaymentsView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'report/:id',
        name: 'report',
        component: ReportsView,
        meta: { 
          roles: ['super-admin', 'admin', 'accountant']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'transfare',
        name: 'transfare',
        component: TransfareView,
        meta: { 
          roles: ['super-admin', 'admin']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'users',
        name: 'users',
        component: UsersView,
        meta: { 
          roles: ['super-admin', 'admin']
        },
        beforeEnter: checkRole,
      },
      {
        path: 'potential-customers',
        name: 'potential-customers',
        component: PotentialCustomersView,
        meta: { 
          roles: ['super-admin', 'admin', 'reception']
        },
        beforeEnter: checkRole,
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




export default router

