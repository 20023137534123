<template>
  <div>
    <!-- Add Modal -->
    <div
      class="modal fade"
      id="modalEdit"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalEditTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-side" role="document">
        <div class="modal-content modal-content-side">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalTitle">
              <i class="fa fa-plus"></i>
              تعديل الصور
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body modal-side">
            <div class="form-group">
              <label for="title">الاسم</label>
              <input
                type="text"
                class="form-control red-input-border"
                name="title"
                id="title"
                v-model="editForm.title"
              />
              <div v-if="errors.title">
                <p
                  class="input-error"
                  v-for="(error, index) in errors.title"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <label for="description">الوصف</label>
              <textarea
                type="text"
                class="form-control red-input-border"
                name="description"
                id="description"
                v-model="editForm.description"
              ></textarea>
              <div v-if="errors.description">
                <p
                  class="input-error"
                  v-for="(error, index) in errors.description"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="edit-logo" class="edit-logo-label">
                  <div class="upload-image-container">
                    <i class="fa fa-upload" aria-hidden="true"></i>
                    <span class="extentions">PNG, JPEG</span>
                  </div>
                </label>
                <input
                  type="file"
                  class="form-control red-input-border"
                  name="logo"
                  id="edit-logo"
                  accept="image/*"
                  hidden
                  @change="onImageChangeUpdate"
                />
                <div v-if="errors.logo">
                  <p
                    class="input-error"
                    v-for="(error, index) in errors.logo"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </div>
              <div class="form-group col-md-12">
                <img
                  v-if="edit_url"
                  :src="edit_url"
                  class="edit-logo-review"
                  id="logo-preview"
                />
              </div>
            </div>
            <!-- v-if="editForm.type == 'construction'" -->
            <div class="form-group" >
              <label for="building">نسبة الانجاز % </label>
              <input
                type="number"
                class="form-control red-input-border"
                name="image"
                v-model="editForm.building_percentage"
              />
            </div>
            <hr />
            <button
              type="button"
              class="btn btn-primary btn-primary-100"
              @click="updateImage"
            >
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-floppy-o save-icon" v-else></i> تعديل
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- // Add Modal -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      errors: [],
      edit_url: null,
      loading_spinner: false,
      editForm: {
        title: "",
        building_percentage: "",
        description: "",
        image: "",
      },
    };
  },
  watch: {
    "editForm.image": {
      handler(newImage) {
        if (newImage) {
          this.edit_url = URL.createObjectURL(newImage);
        } else {
          this.edit_url = null;
        }
      },
      immediate: true,
    },
  },

  methods: {
    onImageChangeUpdate(e) {
      const file = e.target.files[0];
      this.editForm.image = file;
      this.edit_url = URL.createObjectURL(file);
    },
    showImage(id) {
      this.id = id;
      this.$store
        .dispatch("showImage", id)
        .then((response) => {
          $("#modalEdit").modal("show");
          this.editForm.title = response.data.title;
          this.editForm.description = response.data.description;
          this.editForm.building_percentage = response.data.building_percentage;
          this.edit_url = response.data.image;
        })
        .catch((error) => {
          this.$swal({
            title: "خطأ",
            text: "حدث خطأ أثناء تحميل البيانات",
            type: "error",
            confirmButtonText: "حسناً",
          });
        });
    },
    updateImage() {
      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;
      let data = {
        id: this.id,
        form: this.editForm,
      };
      this.$store
        .dispatch("editImage", data)
        .then((response) => {
          this.$swal.fire("تم", "تم تعديل  بنجاح", "success");
          $("#modalEdit").modal("hide");
          this.$emit("getData");
          this.loading_spinner = false;
        })
        .catch((error) => {
          this.loading_spinner = false;
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>
