<template>
    <div>
        <!-- Add Modal -->
        <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="addModalTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-side" role="document">
                <div class="modal-content modal-content-side">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addModalTitle">
                            <i class="fa fa-plus"></i>
                            اضافة
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body modal-side">
                        <div class="form-group">
                            <label for="name">الاسم</label>
                            <input type="text" class="form-control red-input-border" name="name" id="name"
                                v-model="form.name">
                            <div v-if="errors.name">
                                <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{
                                    error }}</p>
                            </div>

                        </div>


                        <div class="form-group">
                            <label for="type">النوع</label>
                            <select class="form-control red-input-border" id="type" v-model="form.type">
                                <option value="vertical">عمودي</option>
                                <option value="horizontal">افقي</option>
                            </select>
                            <div v-if="errors.type">
                                <p class="input-error" v-for="(error, index) in errors.type" :key="index">{{ error }}
                                </p>
                            </div>
                        </div>


                        <div class="form-group">
                            <label for="address">العنوان</label>
                            <input type="text" class="form-control red-input-border" name="address" id="address"
                                v-model="form.address">
                            <div v-if="errors.address">
                                <p class="input-error" v-for="(error, index) in errors.address" :key="index">{{
                                    error }}</p>
                            </div>

                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="lng">خطوط الطول</label>
                                <input type="string" class="form-control red-input-border" id="lng" v-model="form.lng">
                                <div v-if="errors.lat">
                                    <p class="input-error" v-for="(error, index) in errors.lat" :key="index">{{
                                    error }}</p>
                                </div>

                            </div>
                            <div class="form-group col-md-6">
                                <label for="lat">خطوط العرض</label>
                                <input type="string" class="form-control red-input-border" id="lat" v-model="form.lat">
                                <div v-if="errors.lng">
                                    <p class="input-error" v-for="(error, index) in errors.lng" :key="index">{{
                                    error }}</p>
                                </div>

                            </div>
                        </div>
                        <div class="form-group">
                            <button type="button" class="btn btn-secondary btn-primary-100" data-toggle="modal"
                                data-target="#mapModal">
                                <i class="fa fa-map"></i> الخريطة</button>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="upload-logo" class="upload-logo-label">
                                    <div class="upload-image-container">
                                        <i class="fa fa-upload" aria-hidden="true"></i>
                                        <span class="extentions">PNG, JPEG</span>
                                    </div>
                                </label>
                                <input type="file" class="form-control red-input-border" name="logo" id="upload-logo"
                                    accept="image/*" hidden @change="onFileChange">
                                <div v-if="errors.logo">
                                    <p class="input-error" v-for="(error, index) in errors.logo" :key="index">{{
                                    error }}</p>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <img v-if="url" :src="url" class="upload-logo-review" id="logo-preview" />
                            </div>
                        </div>
                        <hr>
                        <button type="button" class="btn btn-primary  btn-primary-100" @click="addGroup">
                            <div class="spinner-border" role="status" v-if="loading_spinner">
                                <span class="visually-hidden"></span>
                            </div>
                            <i class="fa fa-floppy-o save-icon" v-else></i> اضافة
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- // Add Modal -->
    </div>
</template>

<script>
export default ({
    props: ['location'],
    data() {
        return {
            errors: [],
            url: null,
            loading_spinner: false,
            form: {
                name: '',
                address: '',
                lat: 33.227872,
                lng: 44.377095,
                logo: null,
                type: 'vertical'
            },
        }
    },
    watch: {
        location: {
            handler(newLocation) {
                this.form.lat = newLocation ? newLocation.lat : 33.227872;
                this.form.lng = newLocation ? newLocation.lng : 44.377095;
            },
            immediate: true,
        },
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            console.log('add', file)
            this.form.logo = file;
            this.url = URL.createObjectURL(file);
        },
        async addGroup() {
            if (this.loading_spinner) {
                return;
            }
            this.loading_spinner = true;
            await this.$store.dispatch('addGroup', this.form)
                .then(response => {
                    this.$emit('addGroup', response.data.data);
                    this.form = {
                        name: '',
                        address: '',
                        lat: 33.227872,
                        lng: 44.377095,
                        logo: null,
                        type: 'vertical'
                    };
                    this.url = null;
                    $('#addModal').modal('hide');
                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        title: 'تمت الاضافة بنجاح',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.$emit('getData');
                    this.loading_spinner = false;
                })
                .catch(error => {
                    this.errors = error.response.data.errors;
                    this.loading_spinner = false;
                });
        },
    }
})
</script>
