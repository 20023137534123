<template>
    <div class="col-sm-12 col-md-12 col-lg-12 p-t-1">
      <div class="card h-100">
        <div class="card-body-1">

          <div class="row">

            <div class="col-12">
              <div class="payment-info">
                <span class="payment-title">طريقة الدفع</span>
                <span class="payment-value" v-if="report?.payment_method == 'installment'">
                  تقسيط
                  المبلغ ({{ report.number_of_payments }}) + 
                  <span v-if="report.first_amount">دفعة المقدمة (1) + </span>
                  <span v-if="report.receipt_amount">دفعة الاستلام (1)</span>
                </span>
                <span v-else>- نقدا</span>
              </div>
            </div>





            <div class="col-12" v-if="report.percentage_discount">
              <div class="payment-info">
                <span class="payment-title">السعر قبل الخصم</span>
                <span class="payment-value">{{ report.selling_amount | currency }}</span>
              </div>
            </div>

            <div class="col-12" v-if="report.percentage_discount">
              <div class="payment-info">
                <span class="payment-title">الخصم</span>
                <span class="payment-value">{{ report.percentage_discount }} %</span>
              </div>
            </div>

            <div class="col-12" v-if="report.percentage_discount">
              <div class="payment-info">
                <span class="payment-title">السعر بعد الخصم</span>
                <span class="payment-value">{{ report.selling_amount_after_discount | currency }}</span>
              </div>
            </div>

            <div class="col-12" v-if="report.percentage_discount">
              <div class="payment-info" style="    border: 0;">
                <span class="payment-title">مجموع المسدد</span>
                <span class="payment-value">{{ report.selling_amount_after_discount | currency }}</span>
              </div>
            </div>

            

            <div class="col-12" v-if="!report.percentage_discount">
              <div class="payment-info">
                <span class="payment-title">قيمة بيع الوحدة</span>
                <span class="payment-value">{{ report.selling_amount | currency }}</span>
              </div>
            </div>

            <div class="col-12" v-if="!report.percentage_discount">
              <div class="payment-info">
                <span class="payment-title">دفعة المقدمة</span>
                <span class="payment-value">{{ report.first_amount | currency }}</span>
              </div>
            </div>

            <div class="col-12" v-if="!report.percentage_discount">
              <div class="payment-info" style="    border: 0;">
                <span class="payment-title">مجموع المسدد</span>
                <span class="payment-value">{{ report.paid_amount | currency }}</span>
              </div>
            </div>





          </div>

        </div>
      </div>
    </div>
</template>

<script>
export default({
  props: ['report']
})
</script>
