<template>
  <div class="home">
    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">

                <a data-toggle="modal" data-target="#addModal" class="add-btn-options add-btn">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length > 0" @click="confirmDeleteCustomer()" id="deleteButton"
                  class="card-title-options delete-btn">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length == 1" id="editModal" class="card-title-options edit-btn"
                  @click="openEditModal()" data-toggle="modal" data-target="#modalEdit">
                  <i class="fa fa-cog" aria-hidden="true"></i>
                </a>


                <a @click="downloadExcel()" class="add-btn-options excel-btn">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </a>

              </div>

              <div class="right-title">
                <span class="nav-title">العملاء المحتملين</span>
              </div>

            </div>

            <div class="form-group search-group">
              <label for="search">البحث</label>
              <input type="search" class="form-control red-input-border search-input" v-model="search"
                @input="handleSearch" placeholder="اسم او رقم ..." />

            </div>

            <loading-table-component v-if="loading" />

            <div class="table-responsive" v-else>
              <table class="table table-hover" id="selectableTable">

                <thead>
                  <tr>
                    <th scope="col">ت</th>
                    <th scope="col">الاسم</th>
                    <th scope="col">رقم الهاتف</th>
                    <th scope="col">الوظيفة</th>
                    <th scope="col">العنوان</th>
                    <th scope="col">ملاحظات</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id" :class="{ selected: isSelected(item.id) }"
                    @click="handleSelection(item)">
                    <td>{{ getIndex(index) }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.job }}</td>
                    <td>{{ item.address }}</td>
                    <td>{{ item.notes }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-container" v-if="!loading">
                <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="getCustomers" />
              </div>

            </div>

          </div>
        </div>


      </div>

    </div>
    <AddPotentialCustomerComponent @getData="getCustomers" />
    <EditPotentialCustomerComponent ref="editPotentialCustomerComponent" @getData="getCustomers" />
  </div>
</template>


<script>
import AddPotentialCustomerComponent from '@/components/PotentialCustomer/AddPotentialCustomerComponent.vue';
import EditPotentialCustomerComponent from '@/components/PotentialCustomer/EditPotentialCustomerComponent.vue';

export default {
  components: {
    AddPotentialCustomerComponent,
    EditPotentialCustomerComponent
  },
  data() {
    return {
      loading: false,
      search: '',
      url: null,
      items: [],
      currentPage: 1,
      lastPage: 1,
      selectedItems: [],
      edit_id: null,
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    downloadExcel() {
      let base_url = this.$axios.defaults.baseURL;
      let token = localStorage.getItem('token');
      let exportUrl = `${base_url}/export/potential_customers?token=${token}`;
      window.location.href = exportUrl;
    },

    getCustomers(currentPage) {
      this.loading = true;
      this.$store.dispatch('getCustomers', {
        page: currentPage,
        search: this.search,
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
        });
    },
    openEditModal() {
      let id = this.selectedItems[0];
      this.$refs.editPotentialCustomerComponent.getCustomer(id);
      $('#modalEdit').modal('show');
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getCustomers();
      }, 500);
    },
    getIndex(index) {
      return index + 1 + (this.currentPage - 1) * 10;
    },
    isSelected(itemId) {
      return this.selectedItems.includes(itemId);
    },
    handleSelection(item) {
      if (this.isSelected(item.id)) {
        this.selectedItems = this.selectedItems.filter((id) => id !== item.id);
      }
      else {
        this.selectedItems.push(item.id);
      }
    },
    async confirmDeleteCustomer() {
      const result = await this.$swal.fire({
        title: 'هل انت متأكد ؟',
        text: 'لن تتمكن من التراجع عن هذا الاجراء!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'نعم, احذفها!',
        cancelButtonText: 'الغاء',
      });
      if (result.isConfirmed) {
        this.deleteCustomer();
      }
    },
    async deleteCustomer() {
      try {
        await this.$store.dispatch('deleteCustomer', {
          ids: this.selectedItems,
        });
        this.selectedItems = [];
        await this.getCustomers(this.currentPage);
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'تم الحذف بنجاح',
          showConfirmButton: false,
          timer: 1500
        });
      }
      catch (error) {
        await this.$swal.fire('خطآ', 'حدثت مشكلة اثناء الحذف', 'error');
      }
    },
  },
};
</script>