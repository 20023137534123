<template>
  <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="showModalLabel">عرض العقار</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body show-card-body">
          <loading-table-component v-if="loading" />

          <div class="row" v-else>

            <div v-if="info" class="show-info">
              <div class="col-6 show-title">
                <b>اسم او رقم العقار</b>
              </div>
              <div class="col-6 show-value">{{ info.name }}</div>

              <div class="col-6 show-title">
                <b v-if="type == 'buildings'">البناية</b>
                <b v-else>المربع السكني</b>

              </div>
              <div class="col-6 show-value">{{ info.building.name }}</div>

              <div class="col-6 show-title">
                <b>
                  <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع
                    السكني</span>
                  <span v-else>المجمع</span>
                </b>
              </div>
              <div class="col-6 show-value">{{ info.building.complex.name }}</div>

              

              <div class="col-6 show-title">
                <b>نسبة الانجاز</b>
              </div>
              <div class="col-6 show-value">
                %{{ info.progress_ratio }}
              </div>


              <div class="col-6 show-title">
                <b>فئة العقار</b>
              </div>
              <div class="col-6 show-value" v-text="info.type == 'apartment' ? 'شقة' : 'بيت'"></div>

              <div class="col-6 show-title" v-if="info.type == 'apartment'">
                <b>الطابق</b>
              </div>
              <div class="col-6 show-value" v-if="info.type == 'apartment'">
                <span class="badge badge-success" v-if="info.floor">{{ info.floor }}</span>
                <span class="badge badge-successstatus-unavailable" v-else>لا يوجد</span>
              </div>

              <div class="col-6 show-title">
                <b>الخارطة</b>
              </div>
              <div class="col-6 show-value">
                <a v-if="info.map" :href="info.map">عرض</a>
                <span v-else class="statustatus-unavailables-unavailable">لا يوجد</span>
              </div>



              <div class="col-6 show-title">
                <b>واجهة العقار</b>
              </div>
              <div class="col-6 show-value">{{ info.front }}</div>

              <div class="col-6 show-title">
                <b>المساحة الكلية</b>
              </div>
              <div class="col-6 show-value">
                <span class="" v-if="info.total_area">{{ info.total_area }}م2</span>
                <span class="status-unavailable" v-else>لا يوجد</span>
              </div>

              <div class="col-6 show-title">
                <b>مساحة البناء</b>
              </div>
              <div class="col-6 show-value">
                <span class="" v-if="info.building_area">{{ info.building_area }}</span>
                <span class="status-unavailable" v-else>لا يوجد</span>
              </div>

              <div class="col-6 show-title">
                <b>واجهة العقار</b>
              </div>
              <div class="col-6 show-value">
                <span class="" v-if="info.front">{{ info.front }}</span>
                <span class="status-unavailable" v-else>لا يوجد</span>
              </div>

              <div class="col-6 show-title">
                <b>عدد الغرف</b>
              </div>
              <div class="col-6 show-value">
                <span class="" v-if="info.numbers_of_rooms">{{ info.numbers_of_rooms }}</span>
                <span class="status-unavailable" v-else>لا يوجد</span>
              </div>


              <div class="col-6 show-title">
                <b>عدد الصحيات</b>
              </div>
              <div class="col-6 show-value">
                <span class="" v-if="info.number_of_bathrooms">{{ info.number_of_bathrooms }}</span>
                <span class="status-unavailable" v-else>لا يوجد</span>
              </div>

              <div class="col-6 show-title">
                <b>الحالة</b>
              </div>
              <div class="col-6 show-value">
                <span v-if="info.status == 'available'" class="status-available">متاح</span>
                <span v-if="info.status == 'sold'" class="status-unavailable">مباعة</span>
              </div>

            </div>



          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">خروج</button>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
export default ({
  props: {
  },
  data() {
    return {
      id: '',
      info: null,
      loading: false,
    }
  },
  mounted() {
    this.getType();
  },
  methods: {
    getType() {
      this.type = localStorage.getItem('type');
    },
    async showRealEstate(id) {
      this.loading = true;
      this.id = id;
      await this.$store.dispatch('showRealEstate', {
        id: id
      })
        .then(response => {
          this.info = response.data;
          console.log(this.info)
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
      this.loading = false;
    },
  }
})
</script>
