<template>
  <div class="home w-100">
    <!-- Page content -->

    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">

                <a data-toggle="modal" data-target="#addModal" class="add-btn-options add-btn">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length > 0" @click="confirmDelete()" id="deleteButton"
                  class="card-title-options delete-btn">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length == 1" id="editModal" class="card-title-options edit-btn"
                  @click="openEditModal()" data-toggle="modal" data-target="#modalEdit">
                  <i class="fa fa-cog" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length == 1" id="showButton" data-toggle="modal"
                  @click="openShowModal()" data-target="#showModal" class="card-title-options show-btn">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </a>
              </div>

              <div class="right-title">
                <span class="nav-title">{{ title }}</span>
              </div>
            </div>
            <div class="form-group search-group">
              <label for="search">البحث</label>
              <div class="search-switch">
                <label class="switch">
                  <div>
                    <p class="status-text">الحالة</p>
                  </div>
                  <div class="switch-input">
                    <input type="checkbox" v-model="switchValue" />
                    <span class="slider round"></span>
                  </div>
                </label>
              </div>
              <input type="search" class="form-control red-input-border search-input" v-model="search"
                @input="handleSearch" placeholder="اسم او رقم ..." />
            </div>

            <loading-table-component v-if="loading" />

            <div class="table-responsive" v-else>
              <table class="table table-hover" id="selectableTable">
                <thead>
                  <tr>
                    <th scope="col">ت</th>
                    <th scope="col">اسم {{ name }}</th>
                    <th scope="col">نوع {{ name }}</th>
                    <th scope="col" v-if="type == 'buildings'">الطوابق</th>
                    <th scope="col">المجمع</th>
                    <th scope="col">الخدمات</th>
                    <th scope="col">الحالة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id" :class="{ selected: isSelected(item.id) }"
                    @click="handleSelection(item.id)">
                    <td>{{ getIndex(index) }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item?.complex?.type == 'horizontal' ? 'افقي' : 'عمودي' }}</td>
                    <td v-if="type == 'buildings'"> {{ item.floors }}</td>
                    <td>{{ item.complex?.name }}</td>
                    <td>
                      <div v-if="item.service">
                        <span v-for="(service, index) in item.service" :key="index">
                          <span class="badge badge-success" style="margin: 1px;">{{ service }}</span>
                        </span>
                      </div>
                      <div v-else>لا يوجد</div>
                    </td>
                    <td>
                      <span v-if="item.status == 'completed'" class="status-available">مكتمل</span>
                      <span v-if="item.status == 'incompleted'" class="status-unavailable">قيد الانشاء</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="pagination-container" v-if="!loading">
                <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="getBuildings" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <AddBuildingComponent @getData="getBuildings" />
    <EditBuildingComponent ref="editBuildingComponent" @getData="getBuildings" />
    <ShowBuildingComponent ref="showBuildingComponent" />



  </div>
</template>


<script>
import AddBuildingComponent from '@/components/Buildings/AddBuildingComponent.vue';
import EditBuildingComponent from '@/components/Buildings/EditBuildingComponent.vue';
import ShowBuildingComponent from '@/components/Buildings/ShowBuildingComponent.vue';
export default {
  components: {
    AddBuildingComponent,
    EditBuildingComponent,
    ShowBuildingComponent
  },
  data() {
    return {
      title: '',
      name: '',
      type: '',
      switchValue: true,
      search: '',
      loading: false,
      debounceTimer: null,
      status: 'completed',
      items: [
      ],
      selectedItems: [],
      edit_id: null,
      editModal: false,
      currentPage: 1,
      lastPage: 1,
    };
  },
  watch: {
    switchValue(newVal, oldVal) {
      this.selectedItems = [];
      if (newVal) {
        this.status = 'completed';
        this.getBuildings(this.currentPage);
      } else {
        this.status = 'incompleted';
        this.getBuildings(this.currentPage);
      }
    },
  },
  mounted() {
    this.getBuildings(this.currentPage);
    this.getType();
  },
  methods: {
    getType() {
      let type = localStorage.getItem('type');
      this.type = type;
      this.title = type == 'buildings' ? 'ادارة البنايات' : 'ادارة المربعات السكنية';
      this.name = type == 'buildings' ? 'البناية' : 'المربع السكني';
    },
    openEditModal() {
      let id = this.selectedItems[0];
      $('#modalEdit').modal('show');
      this.$refs.editBuildingComponent.showBuilding(id);
    },
    openShowModal() {
      let id = this.selectedItems[0];
      $('#showModal').modal('show');
      this.$refs.showBuildingComponent.showBuilding(id);
    },
    changeStatus() {
      this.switchValue = !this.switchValue;
      console.log(this.switchValue);
    },
    getIndex(index) {
      return index + 1 + (this.currentPage - 1) * 10;
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getBuildings();
      }, 500);
    },
    getBuildings(currentPage) {
      this.loading = true;
      this.$store.dispatch('getBuildings', {
        page: currentPage,
        search: this.search,
        status: this.status,
      })
        .then(response => {
          this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    lected(itemId) {
      return this.selectedItems.includes(itemId);
    },
    handleSelection(itemId) {
      if (this.isSelected(itemId)) {
        this.selectedItems = this.selectedItems.filter((id) => id !== itemId);
      } else {
        this.selectedItems.push(itemId);
      }
    },
    async confirmDelete() {
      const result = await this.$swal.fire({
        title: 'هل انت متأكد ؟',
        text: 'لن تتمكن من التراجع عن هذا الاجراء!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'نعم, احذفها!',
        cancelButtonText: 'الغاء',
      });
      if (result.isConfirmed) {
        this.deleteBuilding();
      }
    },
    async deleteBuilding() {
      try {
        await this.$store.dispatch('deleteBuilding', {
          ids: this.selectedItems,
        });
        this.selectedItems = [];
        await this.getBuildings(this.currentPage);
        this.$swal({
          position: 'center',
          icon: 'success',
          title: 'تم الحذف بنجاح',
          showConfirmButton: false,
          timer: 1500
        });
      }
      catch (error) {
        await this.$swal.fire('خطآ', 'حدثت مشكلة اثناء الحذف', 'error');
      }
    },
    isSelected(itemId) {
      return this.selectedItems.includes(itemId);
    },
  },
};
</script>
