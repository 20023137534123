<template>
  <div>
    <div class="modal fade" id="modalEdit" tabindex="-1" role="dialog" aria-labelledby="modalEditTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-side" role="document">
        <div class="modal-content modal-content-side">
          <div class="modal-header">
            <h5 class="modal-title" id="modalEditTitle">
              <i class="fa fa-plus"></i>
              تعديل المستخدم
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body modal-side">

            <div class="form-group">
              <label for="name">الاسم</label>
              <input type="text" class="form-control red-input-border" v-model="editForm.name">
              <div v-if="errors.name">
                <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{ error }}</p>
              </div>

            </div>

            <div class="form-group">
              <label for="phone">رقم الهاتف</label>
              <input type="text" class="form-control red-input-border" v-model="editForm.phone">
              <div v-if="errors.phone">
                <p class="input-error" v-for="(error, index) in errors.phone" :key="index">{{ error }}</p>
              </div>

            </div>

            <div class="form-group">
              <label for="password">كلمة المرور</label>
              <input type="passowrd" class="form-control red-input-border" v-model="editForm.password">
              <div v-if="errors.password">
                <p class="input-error" v-for="(error, index) in errors.password" :key="index">{{ error }}</p>
              </div>

            </div>


            <div class="form-group">
              <label for="name">
                      <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>
              </label>
              <v-select v-model="editForm.complexes" :options="complexes" label="name" :dir="'rtl'" track-by="id"
                :searchable="true" @search="getGroups" placeholder="ابحث ..." multiple />
              <div v-if="errors.complexes">
                <p class="input-error" v-for="(error, index) in errors.complexes" :key="index">{{ error }}</p>
              </div>
            </div>



            <div class="form-group">
              <label for="role">الصلاحيات</label>
              <select name="role" id="role" class="form-control red-input-border" v-model="editForm.role">
                <option v-if="role == 'super-admin'" value="super-admin">مدير النظام</option>
                <option value="admin">مدير</option>
                <option value="accountant">محاسب</option>
                <option value="reception">موظف استقبال</option>
              </select>
              <div v-if="errors.role">
                <p class="input-error" v-for="(error, index) in errors.role" :key="index">{{ error }}</p>
              </div>
            </div>


            <hr>
            <button type="button" class="btn btn-primary  btn-primary-100" @click="editUser()">
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-floppy-o save-icon" v-else></i> حفظ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      id: '',
      loading_spinner: false,
      complexes: [],
      editForm: {
        name: '',
        phone: '',
        password: null,
        complexes: '',
        role: '',
      },
      errors: [],
      role: null,
    }
  },
  methods: {
    async getToken() {
            await this.$store.dispatch('getToken');
            this.role = this.$store.state.payload.role;
        },
    getGroups(query) {
      this.$store.dispatch('getGroups', {
        search: query,
      })
        .then(response => {
          this.complexes = response.data.data;
        })
        .catch(error => {
        });
    },
    getUser(id) {
      this.id = id;
      this.loading_spinner = false;
      this.$store.dispatch('getUser', id)
        .then(response => {
          this.editForm = response.data;
          this.getToken();
          this.complexes = response.data.complexes;
        })
        .catch(error => {
        });
    },
    async editUser() {
      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;
      var complexes = [];
      this.editForm.complexes.forEach(complex => {
        complexes.push(complex.id);
      });

      await this.$store.dispatch('editUser', {
        id: this.id,
        form: {
          name: this.editForm.name,
          phone: this.editForm.phone,
          password: this.editForm.password,
          role: this.editForm.role,
          complexes: complexes
        }
      })
        .then(response => {
          this.$emit('editUser', response.data);
          $('#modalEdit').modal('hide');
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تم التعديل بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
          this.$emit('getData');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
        this.loading_spinner = false;
    }
  }
}
</script>