<template>
    <div class="col-sm-12 col-md-12 col-lg-12 p-t-1">
        <div class="card h-100">
            <div class="card-title small-card-title" style="margin: 1pc;    text-align: center;"> جدول المستحقات المدفوعة
                وغير المدفوعة </div>
            <div class="card-body-1">

                <div class="row">

                    <div class="col-6">
                        <table id="selectableTable" class="table table-hover">
                            <thead class="paid-thead">
                                <tr>
                                    <th class="print-th" scope="col">
                                        <div class="paid-thead">تاريخ الدفع</div>
                                    </th>
                                    <th class="print-th" scope="col">
                                        <div class="paid-thead">السبب</div>
                                    </th>
                                    <th class="print-th" scope="col">
                                        <div class="paid-thead">نوع الدفع</div>
                                    </th>
                                    <th class="print-th" scope="col">
                                        <div class="paid-thead">المبلغ</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(installment, index) in paid_installments"
                                    :key="index"
                                >
                                    <td>{{ installment.payment_date }}</td>
                                    <td>
                                        <span v-if="report?.payment_method == 'installment'">
                                            <span v-text="installment.payment_number == 1 ? 'دفعة (1) مقدمة' : `دفعة ${installment.payment_number}`"></span>                                        
                                        </span>
                                        <span v-else>
                                            دفعة كاملة 
                                        </span>
                                    </td>
                                        <td>{{ installment.payment_type == 'cash' ? 'نقدا' : 'حساب مصرفي' }}</td>
                                    <td>{{ installment.payment_amount | currency }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>



                    <div class="col-6">
                        <table id="selectableTable" class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="print-th" scope="col">
                                        <div class="unpaid-thead">تاريخ الاستحقاق</div>
                                    </th>
                                    <th class="print-th" scope="col">
                                        <div class="unpaid-thead">السبب</div>
                                    </th>
                                    <th class="print-th" scope="col">
                                        <div class="unpaid-thead">المطلوب</div>
                                    </th>
                                    <th class="print-th" scope="col">
                                        <div class="unpaid-thead">المدفوع</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="!unpaid_installments && unpaid_installments.length > 0">
                                <tr
                                v-for="(unpaid, index) in unpaid_installments"
                                    :key="index"
                                >
                                    <td>{{ unpaid.payment_date }}</td>
                                    <td>القسط {{ unpaid.payment_number }}</td>
                                    <td>{{ unpaid.payment_amount | currency }}</td>
                                    <td>0 IQD</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="center"  v-if="unpaid_installments && unpaid_installments.length == 0">
                                ليس هناك دفعات غير مسددة
                            </div>
                    </div>



                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default ({
  watch: {
  },
  watch: {
  },
    props: ['report', 'paid_installments', 'unpaid_installments']
})
</script>


<style scoped>
th.print-th {
    padding: 0 !important;
}
</style>
