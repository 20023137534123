<template>
  <div class="home">
    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">

                <a data-toggle="modal" data-target="#addModal" class="add-btn-options add-btn">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length > 0" @click="confirmDeleteUser()" id="deleteButton"
                  class="card-title-options delete-btn">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length == 1" id="editModal" class="card-title-options edit-btn"
                @click="openEditModal()"
                data-toggle="modal" data-target="#modalEdit">
                  <i class="fa fa-cog" aria-hidden="true"></i>
                </a>

                <!-- <a v-if="selectedItems && selectedItems.length == 1" id="showButton" class="card-title-options show-btn"
                  data-toggle="modal" data-target="#showModal">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </a> -->
              </div>

              <div class="right-title">
                <span class="nav-title">ادارة المستخدمين</span>
              </div>

            </div>

            <div class="form-group search-group">
              <label for="search">البحث</label>
              <input type="search" class="form-control red-input-border search-input" v-model="search"
              @input="handleSearch" 
                placeholder="اسم او رقم ..." />

            </div>

            <loading-table-component v-if="loading" />

            <div class="table-responsive"  v-else>
            <table class="table table-hover" id="selectableTable">

              <thead>
                <tr>
                  <th scope="col">ت</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">رقم الهاتف</th>
                  <th scope="col">
                    <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>
                  </th>
                  <th scope="col">الصلاحيات</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="item.id" :class="{ selected: isSelected(item.id) }"
                  @click="handleSelection(item)">
                  <td>{{ getIndex(index) }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>
                    <span class="badge badge-primary" v-for="complex in item.complexes" :key="complex.id">
                      {{ complex.name }}
                    </span>
                  </td>
                  <td>
                    <span class="badge badge-secondary">
                      <b v-if="item.role == 'admin'">مدير المجمع</b>
                      <b v-else-if="item.role == 'accountant'">محاسب</b>
                      <b v-else-if="item.role == 'reception'">موظف استقبال</b>
                      <b v-else>مدير النظام</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
              <div class="pagination-container" v-if="!loading">
                <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="getUsers" />
              </div>

          </div>

          </div>
        </div>


      </div>

    </div>
    <AddUserComponent @getData="getUsers"/>
    <EditUserComponent ref="editUserComponent" @getData="getUsers"/>
  </div>
</template>


<script>
import AddUserComponent from '@/components/Users/AddUserComponent.vue';
import EditUserComponent from '@/components/Users/EditUserComponent.vue';

export default {
  components: {
    AddUserComponent,
    EditUserComponent
  },
  data() {
    return {
      loading: false,
      search: '',
      url: null,
      items: [],
      currentPage: 1,
      lastPage: 1,
      selectedItems: [],
      edit_id: null,
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers(currentPage) {
      this.loading = true;
      this.$store.dispatch('getUsers', {
        page: currentPage,
        search: this.search,
      })
      .then((response) => {
        this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
      });
    },
    openEditModal() {
      let id = this.selectedItems[0];
      this.$refs.editUserComponent.getUser(id);
      $('#modalEdit').modal('show');
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getUsers();
      }, 500);
    },
    getIndex(index) {
      return index + 1 + (this.currentPage - 1) * 10;
    },
    isSelected(itemId) {
      return this.selectedItems.includes(itemId);
    },
    handleSelection(item) {
      if (this.isSelected(item.id)) {
        this.selectedItems = this.selectedItems.filter((id) => id !== item.id);
      }
      else {
        this.selectedItems.push(item.id);
      }
    },
    async confirmDeleteUser() {
      const result = await this.$swal.fire({
        title: 'هل انت متأكد ؟',
        text: 'لن تتمكن من التراجع عن هذا الاجراء!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'نعم, احذفها!',
        cancelButtonText: 'الغاء',
      });
      if (result.isConfirmed) {
        this.deleteUser();
      }
    },
    async deleteUser() {
      try {
        await this.$store.dispatch('deleteUser', {
          ids: this.selectedItems,
        });
        this.selectedItems = [];
        await this.getUsers(this.currentPage);
        this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تم الحذف بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
      }
      catch (error) {
        await this.$swal.fire('خطآ', 'حدثت مشكلة اثناء الحذف', 'error');
      }
    },
  },
};
</script>
