<template>
  <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="showModalLabel">عرض مستمسكات العميل</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <loading-table-component v-if="loading" />
          <div v-else>

            <div v-if="(role !== 'super-admin' && role !== 'admin') && info && info.visible == 0">
              <div class="alert alert-danger" style="text-align: center;">لايمكنك الوصول لهذه المعلومات</div>
            </div>
            <div v-else>
              <div v-if="!loading && info && info.attachments && info.attachments.length > 0">
              <div class="attachment-card card" v-for="(attachment, index) in info?.attachments" :key="index">
                <span class="attachemnt-title">{{ attachment.caption }}</span>
                <img :src="attachment.attachment" class="attachment-image">
              </div>
            </div>
            <div v-else>
              <div class="alert alert-warning">
                لا يوجد مستمسكات لهذا العميل
              </div>
            </div>
            </div>

          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">خروج</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default ({
  props: ['role'],
  data() {
    return {
      info: null,
      loading: false,
    }
  },
  methods: {
    async getClient(id) {
      this.loading = true;
      this.id = id;
      await this.$store.dispatch('getClient', id)
        .then(response => {
          this.info = response.data;
          console.log(this.info)
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
      this.loading = false;
    },
  }
})
</script>
