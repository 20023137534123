<template>
  <div class="p-b-1 mt-3">
    <nav class="navbar navbar-expand-lg navbar-light bg-light ">
      <div class="left-options">
        <div class="dropdown">
          <a href="http://" class="nav-options" type="button" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-user-circle-o nav-icon" aria-hidden="true"></i>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" @click="logout()">تسجيل الخروج</a>
          </div>
        </div>


   

        <!-- <div class="dropdown">
          <a href="http://" class="nav-options" type="button" id="notifications" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-bell-o nav-icon" aria-hidden="true"></i>
        </a>
          <div class="dropdown-menu notifications-menu" aria-labelledby="notifications">
            <ul>
              <li class="notification-list">اليوم موعد تسديد الاقساط</li>
              <li class="notification-list">اليوم موعد تسديد الاقساطاليوم موعد تسديد الاقساط</li>
              <li class="notification-list">اليوم موعد تسديد الاقساط</li>
              <li class="notification-list">اليوم موعد تسديد الاقساط</li>
              <li class="notification-list">اليوم موعد تسديد الاقساط</li>
            </ul>
          </div>
        </div> -->

      </div>
      <div class="right-title">
        <span class="nav-title">نظام ادارة المجمعات السكنية</span>
      </div>
    </nav>
  </div>
</template>

<script>
export default({
  methods: {
    logout() {
      this.$emit('logout')
    }
  }
})
</script>
