import Vue from 'vue';

export default {
  actions: {
    getCustomers({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/potential_customer', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    deleteCustomer({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.delete(`/api/potential_customer/${params.ids}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },



    editCustomer({ commit }, data) {
      const groupData = new FormData();
      groupData.append('name', data.form.name);
      groupData.append('phone', data.form.phone);
      groupData.append('address', data.form.address);
      groupData.append('job', data.form.job);
      groupData.append('complex_id', data.form.complex_id);
      groupData.append('notes', data.form.notes);
      groupData.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post(`/api/potential_customer/${data.id}`, groupData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    addCustomer({ commit }, form) {
      const realEstateData = new FormData();
      realEstateData.append('name', form.name);
      realEstateData.append('phone', form.phone);
      realEstateData.append('address', form.address);
      realEstateData.append('job', form.job);
      realEstateData.append('notes', form.notes);
      realEstateData.append('complex_id', form.complex_id);
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post('/api/potential_customer', realEstateData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },


    getCustomer({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/potential_customer/${id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

  },
};
