<template>
  <!-- Add Modal -->
  <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="addModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-side" role="document">
      <div class="modal-content modal-content-side">
        <div class="modal-header">
          <h5 class="modal-title" id="addModalTitle">
            <i class="fa fa-plus"></i>
            اضافة عقار
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body modal-side">


          <div class="form-group">
            <label for="name">اسم او رقم العقار</label>
            <input type="text" class="form-control red-input-border" v-model="addForm.name">
            <div v-if="errors.name">
              <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="name" v-if="type == 'buildings'">البناية</label>
            <label for="name" v-else>المربع السكني</label>
            <v-select v-model="selected.building_id" :options="buildings" label="name" :dir="'rtl'" track-by="id"
              :searchable="true" @search="getBuildings" placeholder="بحث.." />
            <div v-if="errors.building_id">
              <p class="input-error" v-for="(error, index) in errors.building_id" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-group">
            <label for="name">فئة العقار</label>
            <v-select v-model="selected.real_estate_category_id" :options="categories" label="name" :dir="'rtl'"
              track-by="id" :searchable="true" @search="getCategories" placeholder="ابحث عن قسم.." />
            <div v-if="errors.real_estate_category_id">
              <p class="input-error" v-for="(error, index) in errors.real_estate_category_id" :key="index">{{ error }}
              </p>
            </div>
          </div>



          <div class="form-group">
            <label for="map">خارطة العقار</label>
            <label for="upload-logo" class="upload-logo-label">
              <div class="upload-image-container">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <span class="extentions">PDF</span>
              </div>
            </label>
            <input type="file" class="form-control red-input-border" id="upload-logo" hidden @change="onFileChange">
            <p>{{ selectedFileName }}</p>
            <div v-if="errors.map">
              <p class="input-error" v-for="(error, index) in errors.map" :key="index">{{ error }}</p>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="total_area">المساحة الكلية</label>
              <input type="number" class="form-control red-input-border" id="total_area" v-model="addForm.total_area">
              <div v-if="errors.total_area">
                <p class="input-error" v-for="(error, index) in errors.total_area" :key="index">{{ error }}</p>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="building_area">مساحة البناء</label>
              <input type="number" class="form-control red-input-border" id="building_area"
                v-model="addForm.building_area">
              <div v-if="errors.building_area">
                <p class="input-error" v-for="(error, index) in errors.building_area" :key="index">{{ error }}</p>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="front">واجهة العقار</label>
              <input type="number" class="form-control red-input-border" id="front" v-model="addForm.front">
              <div v-if="errors.front">
                <p class="input-error" v-for="(error, index) in errors.front" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label for="numbers_of_rooms">عدد الغرف</label>
              <input type="number" class="form-control red-input-border" id="numbers_of_rooms"
                v-model="addForm.numbers_of_rooms">
              <div v-if="errors.numbers_of_rooms">
                <p class="input-error" v-for="(error, index) in errors.numbers_of_rooms" :key="index">{{ error }}</p>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="lng">عدد الصحيات </label>
              <input type="number" class="form-control red-input-border" id="lng" v-model="addForm.number_of_bathrooms">
              <div v-if="errors.number_of_bathrooms">
                <p class="input-error" v-for="(error, index) in errors.number_of_bathrooms" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label for="lng">نسبة الانجاز (%)</label>
              <input type="number" class="form-control red-input-border" min="0" max="100" id="lng" v-model="addForm.progress_ratio">
              <div v-if="errors.progress_ratio">
                <p class="input-error" v-for="(error, index) in errors.progress_ratio" :key="index">{{ error }}</p>
              </div>
            </div>

          </div>


          <div class="form-row" v-if="type == 'buildings'">
            <div class="form-group col-md-12">
              <label for="lat">الطابق</label>
              <input type="number" class="form-control red-input-border" id="lat" v-model="addForm.floor">
              <div v-if="errors.floor">
                <p class="input-error" v-for="(error, index) in errors.floor" :key="index">{{ error }}</p>
              </div>
            </div>
          </div>

          <hr>
          <button type="button" class="btn btn-primary  btn-primary-100" @click="addRealEstate">
            <div class="spinner-border" role="status" v-if="loading_spinner">
              <span class="visually-hidden"></span>
            </div>
            <i class="fa fa-floppy-o save-icon" v-else></i> اضافة
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- // Add Modal -->
</template>

<script>
export default ({
  data() {
    return {
      type: '',
      selectedFileName: '',
      loading_spinner: false,
      buildings: [],
      types: [],
      categories: [],
      errors: [],
      selected: {
        building: null,
        real_estate_category: null,
      },
      addForm: {
        name: '',
        building_id: '',
        real_estate_category_id: '',
        total_area: '',
        building_area: '',
        front: '',
        numbers_of_rooms: '',
        number_of_bathrooms: '',
        floor: '',
        map: '',
        progress_ratio: 0,
      },
    }
  },
  mounted() {
    this.getBuildings();
    this.getCategories();
  },
  methods: {
    getType() {
      this.type = localStorage.getItem('type');
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.selectedFileName = file ? file.name : null;
      this.addForm.map = file;
    },
    getBuildings(query) {
      this.$store.dispatch('getBuildings', {
        search: query,
      })
        .then(response => {
          this.buildings = response.data.data;
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    getCategories(query) {
      this.$store.dispatch('getRealEstateCategory', {
        search: query,
      })
        .then(response => {
          this.categories = response.data.data;
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    getTypes(query) {
      this.$store.dispatch('getRealEstateType', {
        search: query,
      })
        .then(response => {
          this.types = response.data.data;
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    async addRealEstate() {
      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;
      this.addForm.building_id = this.selected.building_id == null ? null : this.selected.building_id.id;
      this.addForm.real_estate_category_id = this.selected.real_estate_category_id == null ? null : this.selected.real_estate_category_id.id;
      await this.$store.dispatch('addRealEstate', this.addForm)
        .then(response => {
          this.$emit('addRealEstate', response.data.data);
          $('#addModal').modal('hide');
          this.addForm = {
            name: '',
            building_id: '',
            real_estate_category_id: '',
            type: '',
            total_area: '',
            building_area: '',
            front: '',
            numbers_of_rooms: '',
            number_of_bathrooms: '',
            floor: '',
            map: '',
          }
          this.selected = {
            building: null,
            real_estate_category: null,
          }
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تمت الاضافة بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
          this.$emit('getData');
          this.loading_spinner = false;
        })
        .catch(error => {
          this.errors = error.response.data.errors;
          this.loading_spinner = false;
        });
    }
  }
})
</script>
