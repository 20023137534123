<template>
    <div class="home w-100">
        <div class="content">
            <div class="">
                <div class="card">
                    <div class="card-body">
                        <div class="card-title">
                            <div class="left-options card-title-icons">
                            </div>
                            <div class="right-title">
                                <span class="nav-title">{{ title }}</span>
                            </div>
                        </div>
                        <div v-if="!show_upload_form">
                            <div class="form-group" v-if="paramExists && selected_contract">
                                <label for="name">رقم العقد</label>
                                <input type="number" class="form-control red-input-border" id="payment-number"
                                    v-model="selected_contract.contract_number" disabled>
                            </div>
                            <div class="form-group" v-else>
                                <label for="name">رقم العقد</label>
                                <v-select v-model="selected_contract" :options="contracts" label="contract_number"
                                    :dir="'rtl'" track-by="id" :searchable="true" @search="getContractByNumber"
                                    placeholder="ابحث عن رقم العقد.." />
                                <div v-if="errors.sale_id">
                                    <p class="input-error" v-for="(error, index) in errors.sale_id" :key="index">{{
                                        error
                                    }}</p>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="payment-number">رقم الدفعة</label>
                                <input type="number" class="form-control red-input-border" id="payment-number"
                                    v-model="addForm.payment_number">
                                <div v-if="errors.payment_number">
                                    <p class="input-error" v-for="(error, index) in errors.payment_number" :key="index">{{
                                        error
                                    }}</p>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="payment-type">نوع التسديد</label>
                                <select v-model="addForm.payment_type" id="payment-type"
                                    class="form-control red-input-border">
                                    <option value="cash">نقدا</option>
                                    <option value="bank">تحويل مصرفي</option>
                                </select>
                                <div v-if="errors.payment_type">
                                    <p class="input-error" v-for="(error, index) in errors.payment_type" :key="index">{{
                                        error
                                    }}</p>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="payment_date">تاريخ الاستحقاق</label>
                                <input type="date" class="form-control red-input-border" id="payment_date"
                                    v-model="addForm.payment_date">
                                <div v-if="errors.payment_date">
                                    <p class="input-error" v-for="(error, index) in errors.payment_date" :key="index">{{
                                        error
                                    }}</p>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="amount">المبلغ</label>
                                <input type="text" class="form-control red-input-border" id="amount"
                                    v-model="addForm.payment_amount">
                                <div v-if="errors.payment_amount">
                                    <p class="input-error" v-for="(error, index) in errors.payment_amount" :key="index">{{
                                        error
                                    }}</p>
                                </div>
                            </div>

                            <div class="form-group" v-if="selected_contract">
                                <table class="table table-hover" id="selectableTable">
                                    <thead>
                                        <tr>
                                            <th scope="col">اسم العميل</th>
                                            <th scope="col">العقار</th>
                                            <th scope="col">تاريخ العقد</th>
                                            <th scope="col">رقم العقد</th>
                                            <th scope="col">نوع التسديد</th>
                                            <th scope="col">رقم اخر دفعة</th>
                                            <th scope="col">تاريخ اخر دفعة</th>
                                            <th scope="col">المدفوع</th>
                                            <th scope="col">المتبقي</th>
                                            <th scope="col">تاريخ الاستحقاق</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{ selected_contract.client.name }}</td>
                                            <td>{{ selected_contract.real_estate.name }}</td>
                                            <td>{{ selected_contract.contract_date }}</td>
                                            <td>{{ selected_contract.contract_number }}</td>
                                            <td>{{ selected_contract.payment_method == 'installment' ? 'اقساط' : 'نقذا' }}
                                            </td>
                                            <td>{{ selected_contract.last_payment ?
                                                selected_contract.last_payment.payment_number : 'لا يوجد' }}</td>
                                            <td>{{ selected_contract.last_payment ?
                                                selected_contract.last_payment.payment_date : 'لا يوجد' }}</td>
                                            <td>{{ selected_contract.paid_amount | currency }}</td>
                                            <td>{{ selected_contract.remaining_amount | currency }}</td>
                                            <td>{{ selected_contract.due_date }}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            <div
                                v-if="!loading_spinner && selected_contract && selected_contract.payment_method == 'installment'">
                                <div
                                    v-if="selected_contract && addForm.payment_amount && addForm.payment_number && paymentable">
                                    <button type="submit" class="btn btn-primary" @click="paySubmit()">
                                        <i class="fa fa-money"></i> تسديد
                                    </button>

                                    <button type="submit" class="btn btn-secondary pay-print-btn" @click="payAndPrint()">
                                        <i class="fa fa-print"></i> تسديد وطباعة
                                    </button>
                                </div>
                            </div>

                            <div class="alert alert-warning" v-if="!paymentable">جميع اقساطه مسددة بالفعل</div>

                        </div>


                        <div v-else>
                            <label for="map">صورة الوصل المختوم</label>
                            <div class="form-group">
                                <label for="signed-voucher" class="signed-voucher-label">
                                    <div class="upload-image-container">
                                        <i class="fa fa-upload" aria-hidden="true"></i>
                                        <span class="extentions">صورة الوصل</span>
                                    </div>
                                </label>
                                <input type="file" class="form-control red-input-border" id="signed-voucher" hidden
                                    @change="onFileChange">
                                <div v-if="errors.map">
                                    <p class="input-error" v-for="(error, index) in errors.map" :key="index">{{ error }}</p>
                                </div>
                            </div>

                            <div class="form-group" v-if="signed_voucher_preview">
                                <img :src="signed_voucher_preview" class="voucher-preview">
                            </div>

                            <button type="submit" class="btn btn-primary" @click="uploadAttachmet()">
                                <div class="spinner-border" role="status" v-if="loading_spinner">
                                    <span class="visually-hidden"></span>
                                </div>
                                <i class="fa fa-paperclip" aria-hidden="true"></i> ارفاق
                            </button>
                        </div>



                    </div>
                </div>
            </div>
        </div>


        <PrintInvoiceComponent ref="printInvoiceComponent" />




    </div>
</template>
  
<script>
import PrintInvoiceComponent from '@/components/Global/PrintInvoiceComponent.vue';
export default ({
    components: {
        PrintInvoiceComponent,
    },
    data() {
        return {
            title: 'تسديد الدفعات',
            loading_spinner: false,
            current_date: new Date().toLocaleDateString(),
            errors: [],
            contracts: [],
            sale: [],
            new_contract_id: [],
            selected_contract: null,
            addForm: {
                sale_id: '',
                payment_amount: '',
                payment_number: '',
                payment_date: '',
                payment_type: 'cash'
            },
            upload: {
                payment_id: '',
                signed_voucher: ''
            },
            show_upload_form: false,
            paymentable: true,
            signed_voucher_preview: false
        }
    },
    watch: {
        selected_contract(newVal, oldVal) {
            this.addForm.payment_amount = newVal.installment_amount
            if (newVal.remaining_amount == 0) {
                this.paymentable = false
            } else {
                this.paymentable = true
                this.currentPaymentNumber();
            }
        }
    },
    computed: {
        paramExists() {
            return this.$route.params.hasOwnProperty('id');
        },
    },
    mounted() {
        if (this.paramExists) {
            this.getSale();
        }
    },
    mounted() {
        this.getContractByNumber();
    },
    methods: {
        onFileChange(e) {
            const file = e.target.files[0];
            this.signed_voucher_preview = URL.createObjectURL(file);
            console.log(this.signed_voucher_preview)
            this.upload.signed_voucher = file;
        },
        currentPaymentNumber() {
            if (this.selected_contract) {
                if (this.selected_contract.last_payment) {
                    this.addForm.payment_number = this.selected_contract.last_payment.payment_number + 1
                } else {
                    this.addForm.payment_number = 1
                }
            } else {
                this.addForm.payment_number = 1;
            }
        },
        getSale() {
            this.$store.dispatch('showSale', this.$route.params.id)
                .then(response => {
                    this.selected_contract = response.data
                })
                .catch(error => {
                    console.log(error.response.data.errors);
                });
        },
        async uploadAttachmet() {
            this.loading_spinner = true;
            await this.$store.dispatch('uploadAttachmet', {
                data: this.upload
            })
                .then(response => {
                    this.$swal({
                        title: 'تمت العملية بنجاح',
                        type: 'success',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.$router.back();
                })
                .catch(error => {
                    this.$swal({
                        title: 'حدث خطأ',
                        type: 'error',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
            this.loading_spinner = false;

        },
        getContractByNumber(query) {
            this.$store.dispatch('getContractByNumber', {
                search: query,
            })
                .then(response => {
                    this.contracts = response.data.data;
                })
                .catch(error => {
                    console.log(error.response.data.errors);
                });
        },
        async payAndPrint() {
            this.loading_spinner = true;
            try {
                await this.doPayment(true);
                this.selected_contract = null;
            } catch (error) {
                this.$swal({
                    title: 'حدث خطأ',
                    type: 'error',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            this.loading_spinner = false;
        },

        async paySubmit() {
            this.loading_spinner = true;
            await this.doPayment();
            if (this.paramExists) {
                this.$router.back();
            }
            this.addForm = {
                sale_id: '',
                payment_amount: '',
                payment_number: '',
                payment_date: '',
                payment_type: 'cash',
            };
            this.selected_contract = null;
            $('#myLargeModalLabel').modal('hide');
            this.loading_spinner = false;
        },

        async doPayment(print = false) {
            this.addForm.sale_id = this.selected_contract.id;
            await this.$store.dispatch('payment', {
                data: this.addForm,
            })
                .then(response => {
                    this.errors = [];
                    this.addForm = {
                        sale_id: '',
                        payment_amount: '',
                        payment_number: '',
                        payment_date: '',
                    };

                    if (print) {
                        this.upload.payment_id = response.data.data.id;
                        this.$refs.printInvoiceComponent.print(response.data.data.id, true);
                        this.title = 'ارفق الوصل المختوم';
                        this.show_upload_form = true
                    } else {
                        this.$swal({
                            title: 'تمت العملية بنجاح',
                            type: 'success',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }

                })
                .catch(error => {
                    this.errors = error.response.data.errors;
                });
        },
        getPaied(old_paid, new_paid) {
            const oldPaidInt = parseInt(old_paid, 10) || 0;
            const newPaidInt = parseInt(new_paid, 10) || 0;
            return oldPaidInt + newPaidInt;
        },
    }
})
</script>
  


<style scoped>
select#payment-type {
    width: auto;
}

.upload-image-container {
    width: 10pc;
    height: 6pc;
}

img.voucher-preview {
    width: 18pc;
    border-radius: 1pc;
    border: 3px solid #a33948;
}
</style>