<template>
  <div>
    <div class="modal fade" id="modalEdit" tabindex="-1" role="dialog" aria-labelledby="modalEditTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-side" role="document">
        <div class="modal-content modal-content-side">
          <div class="modal-header">
            <h5 class="modal-title" id="modalEditTitle">
              <i class="fa fa-plus"></i>
              تعديل المستخدم
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body modal-side">

            <div class="form-group">
              <label for="name">الاسم</label>
              <input type="text" class="form-control red-input-border" v-model="editForm.name">
              <div v-if="errors.name">
                <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{ error }}
                </p>
              </div>
            </div>


            <div class="form-group">
              <label for="phone">رقم الهاتف</label>
              <input type="number" class="form-control red-input-border" v-model="editForm.phone">
              <div v-if="errors.phone">
                <p class="input-error" v-for="(error, index) in errors.phone" :key="index">{{ error }}
                </p>
              </div>
            </div>



            <div class="form-group">
              <label for="address">العنوان</label>
              <input type="text" class="form-control red-input-border" v-model="editForm.address">
              <div v-if="errors.address">
                <p class="input-error" v-for="(error, index) in errors.address" :key="index">{{ error }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <label for="job">الوظيفة</label>
              <input type="text" class="form-control red-input-border" v-model="editForm.job">
              <div v-if="errors.job">
                <p class="input-error" v-for="(error, index) in errors.job" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group">
              <label for="notes">ملاحظات</label>
              <input type="text" class="form-control red-input-border" v-model="editForm.notes">
              <div v-if="errors.notes">
                <p class="input-error" v-for="(error, index) in errors.notes" :key="index">{{ error }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <label for="name">
                      <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>
              </label>
              <v-select v-model="selected_complex" :options="complex" label="name" :dir="'rtl'" track-by="id"
                :searchable="true" @search="getGroups" placeholder="ابحث ..." />
              <div v-if="errors.complex_id">
                <p class="input-error" v-for="(error, index) in errors.complex_id" :key="index">{{ error
                  }}</p>
              </div>
            </div>


            <hr>
            <button type="button" class="btn btn-primary  btn-primary-100" @click="editCustomer()">
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-floppy-o save-icon" v-else></i> حفظ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      id: '',
      loading_spinner: false,
      selected_complex: null,
      complex: [],
      editForm: {
        name: '',
        phone: '',
        address: '',
        job: '',
        notes: '',
      },
      errors: [],
      role: null,
    }
  },
  methods: {
    async getToken() {
      await this.$store.dispatch('getToken');
      this.role = this.$store.state.payload.role;
    },
    getGroups(query) {
      this.$store.dispatch('getGroups', {
        search: query,
      })
        .then(response => {
          this.complex = response.data.data;
        })
        .catch(error => {
        });
    },
    getCustomer(id) {
      this.id = id;
      this.loading_spinner = false;
      this.$store.dispatch('getCustomer', id)
        .then(response => {
          this.editForm = response.data;
          this.getToken();
          this.selected_complex = response.data.complex;
          console.log(response.data)
        })
        .catch(error => {
        });
    },
    async editCustomer() {
      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;
      var complex_id = this.selected_complex == null ? null : this.selected_complex.id;
      await this.$store.dispatch('editCustomer', {
        id: this.id,
        form: {
          name: this.editForm.name,
          phone: this.editForm.phone,
          address: this.editForm.address,
          job: this.editForm.job,
          notes: this.editForm.notes,
          complex_id: complex_id
        }
      })
        .then(response => {
          this.$emit('editCustomer', response.data);
          $('#modalEdit').modal('hide');
          this.errors = [];
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تم التعديل بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
          this.$emit('getData');
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
      this.loading_spinner = false;
    }
  }
}
</script>