<template>
  <div class="home w-100">

    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="left-options card-title-icons">
                <a @click="downloadExcel()" class="add-btn-options excel-btn">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </a>
              </div>

              <div class="right-title">
                <span class="nav-title">سجل نقل الملكية</span>
              </div>


            </div>


            <loading-table-component v-if="loading" />
            <div class="table-responsive" v-else>

              <table class="table table-hover" id="selectableTable">
                <thead>
                  <tr>
                    <th scope="col">ت</th>
                    <th scope="col">اسم او رقم العقار</th>
                    <th scope="col">المالك القديم</th>
                    <th scope="col">المالك الجديد</th>
                    <th scope="col">تاريخ النقل</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in items" :key="item.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.real_estate.name }}</td>
                    <td>{{ item.old_client.name }}</td>
                    <td>{{ item.new_client.name }}</td>
                    <td>{{ item.created_at }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-container" v-if="!loading">
                <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="realEstateHistory" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>






    <PrintInvoiceComponent ref="printInvoiceComponent" />




  </div>
</template>


<script>
import PrintInvoiceComponent from '@/components/Global/PrintInvoiceComponent.vue';
export default {
  components: {
    PrintInvoiceComponent,
  },
  data() {
    return {
      search: '',
      loading: false,
      selected_contract: null,
      items: [
      ],
      currentPage: 1,
      lastPage: 1,
      loading_spinner: [],
    };
  },
  mounted() {
    this.realEstateHistory(this.currentPage);
  },
  methods: {
    downloadExcel() {
      let base_url = this.$axios.defaults.baseURL;
      let token = localStorage.getItem('token');
      let exportUrl = `${base_url}/export/transfer?token=${token}`;
      window.location.href = exportUrl;
    },
    async print(id) {
      this.$refs.printInvoiceComponent.print(id);
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.realEstateHistory(this.currentPage);
      }, 500);
    },
    realEstateHistory(currentPage) {
      this.loading = true;
      let params = {
        query: {
          page: currentPage,
          search: this.search,
        },
        id: this.$route.params.id
      }
      this.$store.dispatch('realEstateHistory', params)
        .then(response => {
          this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
        })
        .catch(error => {
          this.loading = false;
        });
    },
  },
};
</script>