import Vue from 'vue';

export default {
  actions: {

    getPayments({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/payment', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    payment({ commit }, form) {
        const paymentData = new FormData();
        paymentData.append('sale_id', form.data.sale_id);
        paymentData.append('payment_amount', form.data.payment_amount);
        paymentData.append('payment_number', form.data.payment_number);
        paymentData.append('payment_date', form.data.payment_date);
        paymentData.append('payment_type', form.data.payment_type);
        console.log(form.data)
        return new Promise((resolve, reject) => {
          Vue.prototype.$axios.post('/api/payment', paymentData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      },


      uploadAttachmet({ commit }, form) {
        const paymentData = new FormData();
        paymentData.append('payment_id', form.data.payment_id);
        paymentData.append('signed_voucher', form.data.signed_voucher);
        return new Promise((resolve, reject) => {
          Vue.prototype.$axios.post('/api/payment/attachment', paymentData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      },

      paymentBySale({ commit }, params) {
        return new Promise((resolve, reject) => {
          Vue.prototype.$axios.get(`/api/payment/list/${params.id}`, { params: params.query })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      },

      printPayment({ commit }, params) {
        return new Promise((resolve, reject) => {
          Vue.prototype.$axios.get(`/api/payment/print/${params.id}`, { params: params.query })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      },


      printPublicPaymentByHash({ commit }, params) {
        return new Promise((resolve, reject) => {
          Vue.prototype.$axios.get(`/api/public/payment/print/${params.id}`, { params: params.query })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      },

  
  },
};
