import Vue from 'vue';

export default {
  actions: {
    getClientsList({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/client', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getContractsNumbers({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/contracts/client/${params.id}`, { params: params.query })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    addClient({ commit }, form) {
      const clientData = new FormData();
      form.files.forEach((file, index) => {
        clientData.append(`images[${index}]`, file);
      });
      form.captions.forEach((caption, index) => {
        clientData.append(`captions[${index}]`, caption);
      });
      clientData.append('name', form.name);
      clientData.append('home_phone', form.home_phone);
      clientData.append('phone', form.phone);
      clientData.append('work_phone', form.work_phone);
      clientData.append('nationality', form.nationality);
      clientData.append('id_number', form.id_number);
      clientData.append('complex_id', form.complex_id);
      clientData.append('marital_status', form.marital_status);
      clientData.append('job', form.job);
      clientData.append('visible', form.visible);
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post('/api/client', clientData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    getClient({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/client/${id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    editClient({ commit }, data) {
      const groupData = new FormData();
      groupData.append('name', data.form.name);
      groupData.append('phone', data.form.phone);
      groupData.append('nationality', data.form.nationality);
      groupData.append('id_number', data.form.id_number);
      groupData.append('home_phone', data.form.home_phone);
      groupData.append('work_phone', data.form.work_phone);
      groupData.append('marital_status', data.form.marital_status);
      groupData.append('complex_id', data.form.complex_id);
      groupData.append('job', data.form.job);
      groupData.append('visible', data.form.visible);
      groupData.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post(`/api/client/${data.id}`, groupData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    deleteClient({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.delete(`/api/client/${params.ids}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};
