<template>
  <div>
    <!-- Add Modal -->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-side" role="document">
        <div class="modal-content modal-content-side">
          <div class="modal-header">
            <h5 class="modal-title" id="addModalTitle">
              <i class="fa fa-plus"></i>
              اضافة صور
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body modal-side">
            <div class="form-group">
              <label for="title">الاسم</label>
              <input
                type="text"
                class="form-control red-input-border"
                name="title"
                id="title"
                v-model="form.title"
              />
              <div v-if="errors.title">
                <p
                  class="input-error"
                  v-for="(error, index) in errors.title"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </div>

            <div class="form-group">
              <label for="description">الوصف</label>
              <textarea
                type="text"
                class="form-control red-input-border"
                name="description"
                id="description"
                v-model="form.description"
              ></textarea>
              <div v-if="errors.description">
                <p
                  class="input-error"
                  v-for="(error, index) in errors.description"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="upload-logo" class="upload-logo-label">
                  <div class="upload-image-container">
                    <i class="fa fa-upload" aria-hidden="true"></i>
                    <span class="extentions">PNG, JPEG</span>
                  </div>
                </label>
                <input
                  type="file"
                  class="form-control red-input-border"
                  name="image"
                  id="upload-logo"
                  accept="image/*"
                  hidden
                  @change="onFileChange"
                />
                <div v-if="errors.image">
                  <p
                    class="input-error"
                    v-for="(error, index) in errors.image"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </div>
              </div>
              <div class="form-group col-md-12">
                <img
                  v-if="url"
                  :src="url"
                  class="upload-logo-review"
                  id="logo-preview"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="type">النوع</label>
              <select
                class="form-control red-input-border"
                id="type"
                v-model="form.type"
              >
                <option value="construction">مراحل البناء</option>
                <option value="building">صور البناية</option>
              </select>
              <div v-if="errors.type">
                <p
                  class="input-error"
                  v-for="(error, index) in errors.type"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>
            </div>
            <!-- /form-group if building type = building -->
            <div class="form-group" v-if="form.type == 'construction'">
              <label for="building">نسبة الانجاز % </label>
              <input
                type="number"
                class="form-control red-input-border"
                name="image"
                v-model="form.building_percentage"
              />
            </div>

            <hr />
            <button
              type="button"
              class="btn btn-primary btn-primary-100"
              @click="addImage"
            >
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-floppy-o save-icon" v-else></i> اضافة
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- // Add Modal -->
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      errors: [],
      url: null,
      loading_spinner: false,
      form: {
        real_estate_id: "",
        title: "",
        description: "",
        image: null,
        type: "building",
        building_percentage: "",
      },
    };
  },
  watch: {
    "form.image": {
      handler(newImage) {
        if (newImage) {
          this.url = URL.createObjectURL(newImage);
        } else {
          this.url = null;
        }
      },
      immediate: true,
    },
  },

  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.form.image = file;
      this.url = URL.createObjectURL(file);
    },

    async addImage() {
      //add valdiations
      if (this.form.title == "") {
        this.$swal({
          position: "top-right",
          icon: "error",
          title: "الصورة مطلوبة",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            popup: "swal2-xs",
          },
        });
        return;
      }
      if (this.form.description == "") {
        this.$swal({
          position: "top-right",
          icon: "error",
          title: "الوصف مطلوب",
          showConfirmButton: false,
          timer: 1500,
        });

        return;
      }
      if (this.form.image == null) {
        this.$swal({
          position: "top-right",
          icon: "error",
          title: "الصورة مطلوبة",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      if (this.form.type == "") {
        this.$swal({
          position: "top-right",
          icon: "error",
          title: "النوع مطلوب",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }

      if (this.loading_spinner) {
        return;
      }
      this.loading_spinner = true;
      this.form.real_estate_id = this.id;
      await this.$store
        .dispatch("addImage", this.form)
        .then((response) => {
          this.$emit("addImage", response.data.data);
          this.form = {
            title: "",
            description: "",
            image: null,
            type: "construction",
            building_percentage: "",
          };
          this.url = null;
          $("#addModal").modal("hide");
          this.$swal({
            position: "center",
            icon: "success",
            title: "تمت الاضافة بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getData");
          this.loading_spinner = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.loading_spinner = false;
        });
    },
  },
};
</script>
