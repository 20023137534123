import Vue from 'vue';

export default {
  actions: {
    getBuildings({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/building', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    addBuilding({ commit }, form) {
      const buildingData = new FormData();
      buildingData.append('name', form.name);
      buildingData.append('address', form.address);
      buildingData.append('floors', form.floors);
      buildingData.append('map', form.map);
      buildingData.append('service', JSON.stringify(form.service));
      buildingData.append('status', form.status);
      buildingData.append('complex_id', form.complex_id);
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post('/api/building', buildingData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    deleteBuilding({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.delete(`/api/building/${params.ids}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    editBuilding({ commit }, form) {
      const buildingData = new FormData();
      buildingData.append('name', form.data.name);
      buildingData.append('address', form.data.address);
      buildingData.append('floors', form.data.floors);
      buildingData.append('map', form.data.map);
      buildingData.append('service', JSON.stringify(form.data.service));
      buildingData.append('complex_id', form.data.complex_id);
      buildingData.append('status', form.data.status);
      buildingData.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post(`/api/building/${form.id}`, buildingData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    showBuilding({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/building/${params.id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};
