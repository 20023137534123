<template>
  <div>
    <div class="modal fade" id="modalEdit" tabindex="-1" role="dialog" aria-labelledby="modalEditTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-side" role="document">
        <div class="modal-content modal-content-side">
          <div class="modal-header">
            <h5 class="modal-title" id="modalEditTitle">
              <i class="fa fa-plus"></i>
              تعديل العميل
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="editForm.visible == 0 && role == 'accountant'">
            <div class="alert alert-danger">لا يمكنك تعديل معلومات العميل</div>
          </div>
          <div class="modal-body modal-side" v-else>

            <div class="form-group">
              <label for="name">الاسم الكامل</label>
              <input type="text" class="form-control red-input-border" v-model="editForm.name">
              <div v-if="errors.name">
                <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group" v-if="role !== 'accountant'">
              <label for="name">
                <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>
              </label>
              <v-select v-model="selected_complex" :options="complexes" label="name" :dir="'rtl'" track-by="id"
                :searchable="true" @search="getGroups" placeholder="ابحث عن مجمع" />
              <div v-if="errors.complex_id">
                <p class="input-error" v-for="(error, index) in errors.complex_id" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group">
              <label for="nationality">الجنسية</label>
              <select id="nationality" class="form-control red-input-border" v-model="editForm.nationality">
                <option value="عراقي">عراقي</option>
                <option value="اخرى">اخرى</option>
              </select>
              <div v-if="errors.nationality">
                <p class="input-error" v-for="(error, index) in errors.nationality" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group" v-if="editForm.nationality == 'اخرى'">
              <label for="nationality">الجنسية</label>
              <input type="text" class="form-control red-input-border" v-model="editForm.other_nationality">
              <div v-if="errors.nationality">
                <p class="input-error" v-for="(error, index) in errors.nationality" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group">
              <label for="job">العمل</label>
              <input type="text" class="form-control red-input-border" v-model="editForm.job">
              <div v-if="errors.job">
                <p class="input-error" v-for="(error, index) in errors.job" :key="index">{{ error }}</p>
              </div>
            </div>


            <div class="form-group">
              <label for="marital_status">الحالة الزوجية</label>
              <select id="marital_status" class="form-control red-input-border" v-model="editForm.marital_status">
                <option value="اعزب">اعزب</option>
                <option value="خاطب">خاطب</option>
                <option value="متزوج">متزوج</option>
              </select>
              <div v-if="errors.marital_status">
                <p class="input-error" v-for="(error, index) in errors.marital_status" :key="index">{{ error }}</p>
              </div>
            </div>

            <div class="form-group">
              <label for="id_number">رقم الهوية</label>
              <input type="number" class="form-control red-input-border" v-model="editForm.id_number">
              <div v-if="errors.id_number">
                <p class="input-error" v-for="(error, index) in errors.id_number" :key="index">{{ error }}</p>
              </div>

            </div>

            <div class="form-group">
              <label for="phone">رقم الهاتف</label>
              <input type="number" class="form-control red-input-border" v-model="editForm.phone">
              <div v-if="errors.phone">
                <p class="input-error" v-for="(error, index) in errors.phone" :key="index">{{ error }}</p>
              </div>

            </div>



            <div class="form-group">
              <label for="home_phone">هاتف المنزل</label>
              <input type="number" class="form-control red-input-border" v-model="editForm.home_phone">
              <div v-if="errors.home_phone">
                <p class="input-error" v-for="(error, index) in errors.home_phone" :key="index">{{ error }}</p>
              </div>

            </div>



            <div class="form-group" v-if="role == 'super-admin' || role == 'admin'">
              <label for="visible">اخفاء المعلومات</label>
              <select v-model="editForm.visible" id="visible" class="form-control red-input-border">
                <option value="1">لا</option>
                <option value="0">نعم</option>
              </select>
              <div v-if="errors.visible">
                <p class="input-error" v-for="(error, index) in errors.visible" :key="index">{{ error }}</p>
              </div>
            </div>



            <hr>
            <button type="button" class="btn btn-primary  btn-primary-100" @click="editClient()">
              <div class="spinner-border" role="status" v-if="loading_spinner">
                <span class="visually-hidden"></span>
              </div>
              <i class="fa fa-floppy-o save-icon" v-else></i> حفظ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  props: ['role'],
  data() {
    return {
      role: '',
      complexes: [],
      id: '',
      loading_spinner: false,
      selected_complex: null,
      editForm: {
        name: '',
        phone: '',
        home_phone: '',
        work_phone: '',
        nationality: '',
        other_nationality: '',
        id_number: '',
        marital_status: '',
        job: '',
        visible: '',
      },
      errors: [],
    }
  },
  mounted() {
    this.getGroups();
    this.getToken();
  },
  methods: {
    async getToken() {
      await this.$store.dispatch('getToken');
      this.role = this.$store.state.payload.role;
    },
    getGroups(query) {
      this.$store.dispatch('getGroups', {
        search: query,
      })
        .then(response => {
          this.complexes = response.data.data;
        })
        .catch(error => {
          console.log(error.response.data.errors);
        });
    },
    getClient(id) {
      this.id = id;
      this.loading_spinner = false;
      this.$store.dispatch('getClient', id)
        .then(response => {
          this.editForm = response.data;
          this.selected_complex = response.data.complex;
          if(response.data.nationality !== 'عراقي') {
            this.editForm.other_nationality = response.data.nationality;
            this.editForm.nationality = 'اخرى';
          }
          this.editForm.complex_id = response.data.complex.id;
        })
        .catch(error => {
        });
    },
    editClient() {
      if (this.loading_spinner) {
        return;
      }

      let nationality = 'عراقي';
      if(this.editForm.nationality == 'اخرى') {
        nationality = this.editForm.other_nationality;
      }
      this.loading_spinner = true;
      this.editForm.complex_id = this.selected_complex ? this.selected_complex.id : null;
      this.editForm.nationality = nationality;

      this.$store.dispatch('editClient', {
        id: this.id,
        form: this.editForm
      })
        .then(response => {
          this.$emit('editClient', response.data);
          $('#modalEdit').modal('hide');
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تم التعديل بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
          this.$emit('getData');
          this.loading_spinner = false;
        })
        .catch(error => {
          this.loading_spinner = false;
          this.errors = error.response.data.errors;
          console.log("error")
          console.log(error.response.data)
        });
    }
  }
}
</script>

<style scoped>
.alert.alert-danger {
  width: 90%;
  left: 5%;
  top: 1pc;
  text-align: center;
}
</style>