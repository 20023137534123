<template>
  <div class="home">
    <!-- Main Content -->
    <div class="content">
      <div class="">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              
              <div class="left-options card-title-icons">

                <a data-toggle="modal" data-target="#addModal" class="add-btn-options add-btn">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length > 0" @click="confirmDeleteClient()" id="deleteButton"
                  class="card-title-options delete-btn">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </a>

                <a v-if="selectedItems && selectedItems.length == 1" id="editModal" class="card-title-options edit-btn"
                @click="openEditModal()"
                data-toggle="modal" data-target="#modalEdit">
                  <i class="fa fa-cog" aria-hidden="true"></i>
                </a>


                <a @click="downloadExcel()" class="add-btn-options excel-btn">
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                </a>


                <a v-if="selectedItems && selectedItems.length == 1" id="showButton" class="card-title-options show-btn"
                  data-toggle="modal" data-target="#showModal"
                  @click="openShowModal()"
                  >
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </a>
              </div>

              <div class="right-title">
                <span class="nav-title">ادارة العملاء</span>
              </div>

            </div>

            <div class="form-group search-group">
              <label for="search">البحث</label>
              <input type="search" class="form-control red-input-border search-input" v-model="search"
              @input="handleSearch" 
                placeholder="اسم او رقم ..." />

            </div>

            <loading-table-component v-if="loading" />

            <div class="table-responsive"  v-else>
            <table class="table table-hover" id="selectableTable">

              <thead>
                <tr>
                  <th scope="col">ت</th>
                  <th scope="col">اسم العميل</th>
                  <th scope="col">الجنسية</th>
                  <th scope="col">رقم الهوية</th>
                  <th scope="col">الحالة الزوجية</th>
                  <th scope="col">الوظيفة</th>
                  <th scope="col">
                      <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>
                  </th>
                  <th scope="col">رقم الهاتف</th>
                  <th scope="col">هاتف المنزل</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="item.id" :class="{ selected: isSelected(item.id) }"
                  @click="handleSelection(item.id)">
                  <td>{{ getIndex(index) }}</td>
                  <td>
                    {{ item.name }}
                    <div class="badge badge-warning" v-if="item.visible == 0">مخفي</div>
                  </td>
                  <td>{{ item.nationality }}</td>
                  <td>{{ item.id_number }}</td>
                  <td>{{ item.marital_status }}</td>
                  <td>{{ item.job }}</td>
                  <td>{{ item.complex.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td v-text="item.home_phone ? item.home_phone : 'لا يوجد'"></td>
                </tr>
              </tbody>
            </table>
              <div class="pagination-container" v-if="!loading">
                <pagination-component :currentPage="currentPage" :lastPage="lastPage" @getData="getClientsList" />
              </div>

          </div>

          </div>
        </div>


      </div>

    </div>
    <AddClientComponent @getData="getClientsList"/>
    <EditClientComponent :role="role" ref="editClientComponent" @getData="getClientsList"/>
    <ShowClientComponent :role="role" ref="showClientComponent" />
  </div>
</template>


<script>
import AddClientComponent from '@/components/Clients/AddClientComponent.vue';
import EditClientComponent from '@/components/Clients/EditClientComponent.vue';
import ShowClientComponent from '@/components/Clients/ShowClientComponent.vue';

export default {
  components: {
    AddClientComponent,
    EditClientComponent,
    ShowClientComponent
  },
  data() {
    return {
      loading: false,
      role: '',
      status: 'active',
      search: '',
      url: null,
      items: [],
      currentPage: 1,
      lastPage: 1,
      selectedItems: [],
      edit_id: null,
    };
  },

  mounted() {
    this.getClientsList();
    this.getRole();
  },
  methods: {
    downloadExcel() {
      let base_url = this.$axios.defaults.baseURL;
      let token = localStorage.getItem('token');
      let exportUrl = `${base_url}/export/clients?token=${token}`;
      window.location.href = exportUrl;
    },
    getRole() {
      const token = localStorage.getItem('token');
      const tokenParts = token.split('.');
      const payload = JSON.parse(unescape(decodeURIComponent(escape(atob(tokenParts[1])))));
      this.role = payload.role
    },
    getClientsList(currentPage) {
      this.loading = true;
      this.$store.dispatch('getClientsList', {
        page: currentPage,
        search: this.search,
        status: this.status,
      })
      .then((response) => {
        this.loading = false;
          this.items = response.data.data;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
      });
    },
    openEditModal() {
      let id = this.selectedItems[0];
      this.$refs.editClientComponent.getClient(id);
      $('#modalEdit').modal('show');
    },
    openShowModal() {
      let id = this.selectedItems[0];
      this.$refs.showClientComponent.getClient(id);
      $('#showModal').modal('show');
    },
    handleSearch() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getClientsList();
      }, 500);
    },
    getIndex(index) {
      return index + 1 + (this.currentPage - 1) * 10;
    },
    isSelected(itemId) {
      return this.selectedItems.includes(itemId);
    },
    handleSelection(itemId) {
      if (this.isSelected(itemId)) {
        this.selectedItems = this.selectedItems.filter((id) => id !== itemId);
      }
      else {
        this.selectedItems.push(itemId);
      }
    },
    async confirmDeleteClient() {
      console.log(this.selectedItems[0])
      const result = await this.$swal.fire({
        title: 'هل انت متأكد ؟',
        text: 'لن تتمكن من التراجع عن هذا الاجراء!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'نعم, احذفها!',
        cancelButtonText: 'الغاء',
      });
      if (result.isConfirmed) {
        this.deleteClient();
      }
    },
    async deleteClient() {
      try {
        await this.$store.dispatch('deleteClient', {
          ids: this.selectedItems,
        });
        this.selectedItems = [];
        await this.getClientsList(this.currentPage);
        this.$swal({
            position: 'center',
            icon: 'success',
            title: 'تم الحذف بنجاح',
            showConfirmButton: false,
            timer: 1500
          });
      }
      catch (error) {
        await this.$swal.fire('خطآ', 'حدثت مشكلة اثناء الحذف', 'error');
      }
    },
  },
};
</script>
