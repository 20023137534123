import Vue from 'vue';

export default {
  actions: {
    getDashboardStatistics({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/dashboard', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
}
}