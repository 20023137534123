<template>
    <div>
        <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-labelledby="addModalTitle"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-side" role="document">
                <div class="modal-content modal-content-side">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addModalTitle">
                            <i class="fa fa-plus"></i>
                            اضافة عميل محتمل
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body modal-side">
                        <div class="form-group">
                            <label for="name">الاسم</label>
                            <input type="text" class="form-control red-input-border" v-model="addForm.name">
                            <div v-if="errors.name">
                                <p class="input-error" v-for="(error, index) in errors.name" :key="index">{{ error }}
                                </p>
                            </div>
                        </div>


                        <div class="form-group">
                            <label for="phone">رقم الهاتف</label>
                            <input type="number" class="form-control red-input-border" v-model="addForm.phone">
                            <div v-if="errors.phone">
                                <p class="input-error" v-for="(error, index) in errors.phone" :key="index">{{ error }}
                                </p>
                            </div>
                        </div>



                        <div class="form-group">
                            <label for="address">العنوان</label>
                            <input type="text" class="form-control red-input-border" v-model="addForm.address">
                            <div v-if="errors.address">
                                <p class="input-error" v-for="(error, index) in errors.address" :key="index">{{ error }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="job">الوظيفة</label>
                            <input type="text" class="form-control red-input-border" v-model="addForm.job">
                            <div v-if="errors.job">
                                <p class="input-error" v-for="(error, index) in errors.job" :key="index">{{ error }}</p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="notes">ملاحظات</label>
                            <input type="text" class="form-control red-input-border" v-model="addForm.notes">
                            <div v-if="errors.notes">
                                <p class="input-error" v-for="(error, index) in errors.notes" :key="index">{{ error }}
                                </p>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="name">
                      <span v-if="this.$store.state.payload.complex_section_title == 'ادارة المربعات السكنية'">المربع السكني</span>
                      <span v-else>المجمع</span>

                            </label>
                            <v-select v-model="selected_complex" :options="complexes" label="name" :dir="'rtl'"
                                track-by="id" :searchable="true" @search="getGroups" placeholder="ابحث ... " />
                            <div v-if="errors.complex_id">
                                <p class="input-error" v-for="(error, index) in errors.complex_id" :key="index">{{ error
                                    }}</p>
                            </div>
                        </div>




                        <hr>
                        <button type="button" class="btn btn-primary  btn-primary-100" @click="addCustomer()">
                            <div class="spinner-border" role="status" v-if="loading_spinner">
                                <span class="visually-hidden"></span>
                            </div>
                            <i class="fa fa-floppy-o save-icon" v-else></i> اضافة
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading_spinner: false,
            complexes: [],
            selected_complex: null,
            addForm: {
                name: '',
                phone: '',
                address: '',
                job: '',
                notes: '',
            },
            errors: [],
            role: null,
        }
    },
    mounted() {
        this.getGroups();
        this.getToken();
    },
    methods: {
        async getToken() {
            await this.$store.dispatch('getToken');
            this.role = this.$store.state.payload.role;
        },
        getGroups(query) {
            this.$store.dispatch('getGroups', {
                search: query,
            })
                .then(response => {
                    this.complexes = response.data.data;
                })
                .catch(error => {
                    console.log(error.response.data.errors);
                });
        },
        async addCustomer() {
            if (this.loading_spinner) {
                return;
            }
            this.loading_spinner = true;
            var complex_id = this.selected_complex == null ? null : this.selected_complex.id;

            var data = {
                name: this.addForm.name,
                phone: this.addForm.phone,
                address: this.addForm.address,
                job: this.addForm.job,
                notes: this.addForm.notes,
                complex_id: complex_id
            }
            await this.$store.dispatch('addCustomer', data)
                .then(response => {
                    this.$emit('addCustomer', response.data);
                    $('#addModal').modal('hide');
                    this.addForm = {
                        name: '',
                        phone: '',
                        address: '',
                        notes: '',
                        compldexes: [],
                        complex_id: '',
                        job: '',
                    }
                    this.errors = [];
                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        title: 'تمت الاضافة بنجاح',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.$emit('getData');
                })
                .catch(error => {
                    this.errors = error.response.data.errors;
                });
            this.loading_spinner = false;
        }
    }
}
</script>