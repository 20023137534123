import Vue from 'vue';

export default {
  actions: {
    async getToken({ commit }) {
      try {
        let token = localStorage.getItem('token');
        const tokenParts = token.split('.');
        if (tokenParts.length === 3) {
          const payload = JSON.parse(unescape(decodeURIComponent(escape(atob(tokenParts[1])))));
          commit('SET_PAYLOAD', payload);
        } else {
          console.error('Invalid token format');
        }
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    },

    checkTokenExpiration({ commit, state }) {
      if (state.token && state.expirationDate) {
        const expirationDate = new Date(state.expirationDate);
        const currentDate = new Date();
  
        if (expirationDate < currentDate) {
          commit('LOGOUT');
          return "false3"; // Indicate that the token has expired
        }
        return "true2"; // Token is still valid
      }
      return "false1"; // No token or expiration date found
    },
  
  },
};
