<template>
  <div class="home w-100">


    <div class="card p-2 m-b-2">
      مرحبا بك يا {{ payload.name }} ...
    </div>
    <div class="card m-t-1">
      <div class="row">

        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="card-statistic border-left">
            <div class="card-statistic-info">
              <div class="card-statistic-icon"><i class="fa fa-building-o"></i></div>
              <div class="card-statistic-title">
                <span v-if="type == 'buildings'">
                  البنايات
                </span>
                <span v-else>
                  المربعات السكنية
                </span>
              </div>
            </div>
            <div class="card-statistic-number">
              <h4>{{ cards.buildings }}</h4>
            </div>
          </div>
        </div>


        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="card-statistic border-left">
            <div class="card-statistic-info">
              <div class="card-statistic-icon"><i class="fa fa-building"></i></div>
              <div class="card-statistic-title">
                <span>العقارات</span>
              </div>
            </div>
            <div class="card-statistic-number">
              <h4>{{ cards.real_estates }}</h4>
            </div>
          </div>
        </div>


        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="card-statistic border-left">
            <div class="card-statistic-info">
              <div class="card-statistic-icon"><i class="fa fa-users"></i></div>
              <div class="card-statistic-title">
                <span>العملاء</span>
              </div>
            </div>
            <div class="card-statistic-number">
              <h4>{{ cards.clients }}</h4>
            </div>
          </div>
        </div>


        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="card-statistic padding-right">
            <div class="card-statistic-info">
              <div class="card-statistic-icon"><i class="fa fa-tags"></i></div>
              <div class="card-statistic-title">
                <span>المبيعات</span>
              </div>
            </div>
            <div class="card-statistic-number">
              <h4>{{ cards.sales }}</h4>
            </div>
          </div>
        </div>



      </div>
    </div>

    <div class="card m-t-1">
      <div class="row">

        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="card-statistic border-left">
            <div class="card-statistic-info">
              <div class="card-statistic-icon"><i class="fa fa-building-o"></i></div>
              <div class="card-statistic-title">
                <span>عدد الدفعات المسددة</span>
              </div>
            </div>
            <div class="card-statistic-number">
              <h4>{{ cards.paid_payments }}</h4>
            </div>
          </div>
        </div>



        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="card-statistic border-left">
            <div class="card-statistic-info">
              <div class="card-statistic-icon"><i class="fa fa-money"></i></div>
              <div class="card-statistic-title">
                <span> دفعات مسددة</span>
              </div>
            </div>
            <div class="card-statistic-number">
              <h4>{{ cards.paid_payments_amount | currency }}</h4>
            </div>
          </div>
        </div>



        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="card-statistic border-left">
            <div class="card-statistic-info">
              <div class="card-statistic-icon"><i class="fa fa-building"></i></div>
              <div class="card-statistic-title">
                <span>عدد الدفعات غير المسددة</span>
              </div>
            </div>
            <div class="card-statistic-number">
              <h4>{{ cards.unpaid_payments }}</h4>
            </div>
          </div>
        </div>



        <div class="col-sm-6 col-md-3 col-lg-3">
          <div class="card-statistic padding-right">
            <div class="card-statistic-info">
              <div class="card-statistic-icon"><i class="fa fa-money"></i></div>
              <div class="card-statistic-title">
                <span> دفعات غير المسددة</span>
              </div>
            </div>
            <div class="card-statistic-number">
              <h4>{{ cards.unpaid_payments_amount | currency }}</h4>
            </div>
          </div>
        </div>



      </div>
    </div>



  </div>
</template>

<script>
export default ({
  data() {
    return {
      chartOptions: {
        responsive: true
      },
      sales: {
        data: [],
        labels: []
      },
      cards: {
        buildings: null,
        real_estates: null,
        clients: null,
        sales: null,
      },
      type: '',
      payload: {
        name: '',
        id: '',
        role: '',
      },
    }
  },

  mounted() {
    this.getDashboardStatistics();
    this.getToken();
    this.getType();
  },
  methods: {
    getType() {
      this.type = localStorage.getItem('type');
    },
    async getToken() {
      await this.$store.dispatch('getToken');
      this.payload = this.$store.state.payload;
    },
    getDashboardStatistics() {
      this.$store.dispatch('getDashboardStatistics')
        .then(response => {
          this.cards = response.data.cards
          this.sales = response.data.sales
          // console.log(this.sales)
        })
        .catch(error => {
          // console.log(error);
        });
    }
  },

})
</script>
