import Vue from 'vue';

export default {
  actions: {
    getSales({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get('/api/sale', { params: params })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    deleteSale({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.delete(`/api/sale/${params.ids}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },



    editSale({ commit }, data) {
      const groupData = new FormData();
      groupData.append('payment_method', data.form.payment_method);
      groupData.append('client_id', data.form.client_id);
      groupData.append('real_estate_id', data.form.real_estate_id);
      groupData.append('contract_date', data.form.contract_date);
      groupData.append('number_of_payments', data.form.number_of_payments);
      groupData.append('selling_amount', data.form.selling_amount);
      groupData.append('monthly_amount', data.form.monthly_amount);
      groupData.append('receipt_amount', data.form.receipt_amount);
      groupData.append('_method', 'PUT');
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post(`/api/sale/${data.id}`, groupData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    addSale({ commit }, form) {
      const realEstateData = new FormData();
      realEstateData.append('first_amount', form.first_amount);
      realEstateData.append('payment_method', form.payment_method);
      realEstateData.append('client_id', form.client_id);
      realEstateData.append('percentage_discount', form.percentage_discount);
      realEstateData.append('real_estate_id', form.real_estate_id);
      realEstateData.append('contract_date', form.contract_date);
      realEstateData.append('number_of_payments', form.number_of_payments);
      realEstateData.append('selling_amount', form.selling_amount);
      realEstateData.append('installment_amount', form.installment_amount);
      realEstateData.append('receipt_amount', form.receipt_amount);
      // realEstateData.append('payment_type', form.payment_type);
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.post('/api/sale', realEstateData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getContractByNumber({ commit }, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/sale/contract_number/${params.search}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    showSale({ commit }, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$axios.get(`/api/sale/${id}`)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

  },
};
