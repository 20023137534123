import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import group from './group';
import building from './building';
import realEstate from './realEstate';
import client from './client';
import report from './report';
import sale from './sale';
import payment from './payment';
import user from './user';
import dashboard from './dashboard';
import potential_customer from './potential_customer';
import images from './images';

Vue.use(Vuex);

export default new Vuex.Store({
  ...state,
  ...getters,
  ...mutations,
  ...actions,
  modules: {
    group,
    building,
    realEstate,
    client,
    report,
    sale,
    payment,
    user,
    dashboard,
    potential_customer,
    images
  },
});
